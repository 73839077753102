import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  bottomsheetOpened: false,
  user: {},
  isAdmin: false,
  isAccounter: false,
  userRole: 'owner'
}

const getters = {
  accountStatus: state => state.status,
  isAccountBottomsheetOpen: state => !!state.bottomsheetOpened,
  user: state => state.user,
  isAdmin: state => state.isAdmin,
  isAccounter: state => state.isAccounter,
  userRole: state => state.userRole
}

const actions = {
  ['ACCOUNT_GET']: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/me', data: {}, method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('ACCOUNT_SUCCESS', resp)
            resolve(resp)
          } else {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          }
        })
        .catch(err => {
          commit('ACCOUNT_ERROR')
          resolve(err)
        })
    })
  },
  ['ACCOUNT_UPDATE']: ({ commit, dispatch }, account) => {
    return new Promise((resolve, reject) => {
      commit('ACCOUNT_UPDATE')
      apiCall({ url: '/api/account/set', data: account, method: 'POST' })
        .then(resp => {
          if (resp.status === 'accountOK') {
            commit('ACCOUNT_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'emailError') {
            commit('ACCOUNT_EMAIL_ERROR')
            resolve(resp)
          } else if (resp.status === 'passwordError') {
            commit('ACCOUNT_PASSWORD_ERROR')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          } else {
            commit('ACCOUNT_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('ACCOUNT_ERROR', err)
          reject(err)
        })
    })
  },
  ['ACCOUNT_REGISTER']: ({ commit }, account) => {
    return new Promise((resolve, reject) => {
      commit('ACCOUNT_JOIN')
      apiCall({ url: '/api/register', data: account, method: 'POST' })
        .then(resp => {
          if (resp.status === 'accountOK') {
            commit('ACCOUNT_JOIN_SUCCESS')
            resolve(resp)
          } else {
            commit('ACCOUNT_ERROR', resp)
            reject(resp)
          }
        })
        .catch(err => {
          if (err.email !== undefined) {
            commit('ACCOUNT_EMAIL_ERROR')
          }
          resolve(err)
        })
    })
  },
  ['ACCOUNT_REMOVE']: ({ commit, dispatch }, account) => {
    return new Promise((resolve, reject) => {
      commit('ACCOUNT_REMOVE')
      apiCall({ url: '/api/account/del', data: account, method: 'POST' })
        .then(resp => {
          if (resp.status === 'accountOK') {
            commit('ACCOUNT_REMOVE_SUCCESS')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            resolve(resp)
          } else if (resp.status === 'passwordError') {
            commit('ACCOUNT_PASSWORD_ERROR')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          }
        })
        .catch(err => {
          commit('ACCOUNT_ERROR', err)
          reject(err)
        })
    })
  },
  ['ACCOUNT_EMAIL_CONFIRM']: ({ commit, dispatch }, emailObj) => {
    return new Promise((resolve, reject) => {
      commit('ACCOUNT_EMAIL_CONFIRM')
      apiCall({ url: '/api/email-confirm', data: emailObj, method: 'POST' })
        .then(resp => {
          if (resp.status === 'emailOK') {
            commit('ACCOUNT_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'emailError') {
            commit('ACCOUNT_ERROR')
            reject(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          }
        })
        .catch(err => {
          commit('ACCOUNT_ERROR', err)
          reject(err)
        })
    })
  },
  ['ACCOUNT_SET']: ({ commit }, user) => {
    return new Promise((resolve) => {
      commit('ACCOUNT_SET', user)
      resolve()
    })
  },
  ['ACCOUNT_BOTTOMSHEET_OPEN']: ({ commit }, user) => {
    return new Promise((resolve) => {
      commit('ACCOUNT_BOTTOMSHEET_OPEN', user)
      resolve()
    })
  },
  ['ACCOUNT_ACCESS_SET']: ({ commit }, account) => {
    return new Promise((resolve, reject) => {
      commit('ACCOUNT_ACCESS_SET')
      apiCall({ url: '/api/account/set', data: account, method: 'POST' })
        .then(resp => {
          if (resp.status === 'accountOK') {
            commit('ACCOUNT_SUCCESS')
            resolve(resp)
          } else {
            commit('ACCOUNT_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('ACCOUNT_ERROR', err)
          reject(err)
        })
      resolve()
    })
  }
}

const mutations = {
  ['ACCOUNT_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['ACCOUNT_JOIN']: (state) => {
    state.status = 'joining'
  },
  ['ACCOUNT_REMOVE']: (state) => {
    state.status = 'removing'
  },
  ['ACCOUNT_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['ACCOUNT_JOIN_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['ACCOUNT_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['ACCOUNT_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.user = resp.user
    state.isAdmin = false
    state.isAccounter = false
    state.user.roles.forEach(function(role) {
      switch (role.role) {
        case 'admin':
          state.isAdmin = true
        break
        case 'accounter':
          state.isAccounter = true
        break
      }
    })
  },
  ['ACCOUNT_ERROR']: (state) => {
    state.status = 'error'
  },
  ['ACCOUNT_EMAIL_ERROR']: (state) => {
    state.status = 'emailError'
  },
  ['ACCOUNT_PASSWORD_ERROR']: (state) => {
    state.status = 'passwordError'
  },
  ['ACCOUNT_EMAIL_CONFIRM']: (state) => {
    state.status = 'confirming'
  },
  ['ACCOUNT_SET']: (state, user) => {
    state.user = user
  },
  ['ACCOUNT_CLEAN']: (state) => {
    state.user = {}
  },
  ['ACCOUNT_BOTTOMSHEET_OPEN']: (state, user) => {
    state.bottomsheetOpened = true
    state.user = user
  },
  ['ACCOUNT_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['ACCOUNT_ACCESS_SET']: (state) => {
    state.status = 'accessSetting'
  },
  ['ACCOUNT_SET_ROLE']: (state, role) => {
    state.userRole = role
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
