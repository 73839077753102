import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  config: [],
  field: {}
}

const getters = {
  configStatus: state => state.status,
  config: state => state.config,
  configField: state => state.field
}

const actions = {
  ['CONFIG_GET']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/config', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('CONFIG_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('CONFIG_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('CONFIG_ERROR')
          resolve(err)
        })
    })
  },
  ['CONFIG_UPDATE']: ({ commit, dispatch }, field) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/config/edit', data: field, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('CONFIG_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('CONFIG_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('CONFIG_ERROR', err)
          reject(err)
        })
    })
  }
}

const mutations = {
  ['CONFIG_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['CONFIG_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['CONFIG_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['CONFIG_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.config = resp.config || []
    state.field = resp.field || {}

  },
  ['CONFIG_ERROR']: (state) => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
