<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Просмотр садовода</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click.stop="editOwner()" v-on="on">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Изменить садовода</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-list>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  {{ obj.last_name }} {{ obj.name }} {{ obj.mid_name }}
              </span>
            </v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Паспорт</v-list-item-title>
                <v-list-item-subtitle>{{ obj.passport || 'Не указан' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Адрес</v-list-item-title>
                <v-list-item-subtitle>{{ obj.address || 'Не указан' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Телефон</v-list-item-title>
                <v-list-item-subtitle>{{ obj.tel || 'Не указан' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{ obj.email || 'Не указан' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Адрес</v-list-item-title>
                <v-list-item-subtitle>{{ obj.address || 'Не указан' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(field, index) in fields" :key="'f' + index">
              <v-list-item-content>
                <v-list-item-title>{{ field.description }}</v-list-item-title>
                <v-list-item-subtitle>{{ getFieldValue(field) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Участки
              </span>
            </v-subheader>
            <v-list-item v-for="(share, index) in obj.shares" :key="'s' + index">
              <v-list-item-content>
                <v-list-item-title>{{ share.cluster }}-{{ share.line }}-{{ share.house }}</v-list-item-title>
                <v-list-item-subtitle>В собственности с {{ share.pivot.owned_since !== '0000-00-00' ? $moment(new Date(share.pivot.owned_since)).format('DD.MM.YYYY') : 'незапамятных времён' }} по {{ share.pivot.owned_till ? $moment(new Date(share.pivot.owned_till)).format('DD.MM.YYYY') : 'настоящее время' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Родственники
              </span>
            </v-subheader>
            <template v-if="obj.relatives.length">
              <v-list-item v-for="(relative, index) in obj.relatives" :key="'r' + index">
                <v-list-item-content>
                  <v-list-item-title>{{ relative.name }}</v-list-item-title>
                  <v-list-item-subtitle><a :href="'tel:' + relative.tel">{{ relative.tel }}</a></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Не указаны</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Долг
              </span>
            </v-subheader>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Долг ЧЛ</th>
                    <th>Долг Ц</th>
                    <th>Долг НЦ</th>
                    <th>Долг Э</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td :class="getClass(obj.m_debt)">{{ obj.m_debt || "-"}}</td>
                    <td :class="getClass(obj.t_debt)">{{ obj.t_debt || "-"}}</td>
                    <td :class="getClass(obj.nt_debt)">{{ obj.nt_debt || "-"}}</td>
                    <td :class="getClass(obj.e_debt)">{{ obj.e_debt || "-"}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Начисления
              </span>
            </v-subheader>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Найти"
              @input="updateProps()"
            ></v-text-field>
            <v-data-table
              fixed-header
              :headers="listHeaders"
              :items="listData.data"
              :options.sync="listOptions"
              :server-items-length="listData.total"
              :loading="dataLoading"
              :footer-props="listFooterProps"
              @update:options="updateProps()"
              >
              <template v-slot:item="{ item }">
                <tr>
                  <td :class="getClass(item.payment_status)">
                    <text-highlight :queries="searchArray">{{ item.uin }}</text-highlight>
                  </td>
                  <td :class="getClass(item.payment_status)">
                    <text-highlight :queries="searchArray">{{ item.clh }}</text-highlight>
                  </td>
                  <td :class="getClass(item.payment_status)">
                    <text-highlight :queries="searchArray">{{ item.payment_amount }}</text-highlight>
                  </td>
                  <td :class="getClass(item.payment_status)">
                    <text-highlight :queries="searchArray">{{ $moment(new Date(item.date_till)).format('DD.MM.YYYY') }}</text-highlight>
                  </td>
                  <td :class="getClass(item.payment_status)">
                    <text-highlight :queries="searchArray">{{ item.purpose }}</text-highlight>
                  </td>
                  <td :class="getClass(item.payment_status)">
                    <text-highlight :queries="searchArray">{{ item.payment_status }}</text-highlight>
                    <v-btn v-if="item.payment_status === 'не оплачено'" small outlined class="ml-2" @click="makeReceipt(item)">Квитанция</v-btn>
                  </td>
                  <td :class="getClass(item.payment_status)">
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn icon >
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <v-list dense>
                        <v-subheader>
                          <span class="d-inline-block text-truncate">
                              Информация
                          </span>
                        </v-subheader>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Идентификатор начисления</v-list-item-title>
                            <v-list-item-subtitle>{{ item.charge_id }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Дата начисления</v-list-item-title>
                            <v-list-item-subtitle>{{ $moment(new Date(item.date_create)).format('DD.MM.YYYY') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.amount">
                          <v-list-item-content>
                            <v-list-item-title>Сумма начисления</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.amount }}
                              <template> ₽</template>
                              <template v-if="item.type === 'членские'"> за м²</template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Тип начисления</v-list-item-title>
                            <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Статус начисления</v-list-item-title>
                            <v-list-item-subtitle>{{ item.status }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      obj: {
        extra: {},
        relatives: [],
        shares: []
      },
      fields: [],
      listHeaders: [
        { text: 'УИН', value: 'uin' },
        { text: 'Участок', value: 'clh' },
        { text: 'Сумма', value: 'payment_amount' },
        { text: 'Оплатить до', value: 'date_till' },
        { text: 'Назначение', value: 'purpose' },
        { text: 'Статус', value: 'payment_status' },
        { text: 'Инфо', value: 'info', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['uin'],
        sortDesc: [true],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: ''
    }
  },
  computed: {
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('OWNERS_BOTTOMSHEET_CLOSE')
      this.$store.commit('OWNERS_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    editOwner: function () {
      this.$router.push('/owners/edit/')
    },
    getOwner: function () {
      this.$store.dispatch('OWNERS_GET_OWNER', this.$store.getters.owner).then(() => {
        if (this.$store.getters.ownersStatus === 'success') {
          this.obj = this.$store.getters.owner
          this.$root.setTitle('Просмотр садовода ' + this.obj.last_name + ' ' + this.obj.name + ' ' + this.obj.mid_name)
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    getFieldValue: function (field) {
      let value = ''
      try {
        switch (field.type) {
          case 'checkbox':
            value = this.obj.extra['f' + field.id ] === true ? 'Да' : (this.obj.extra['f' + field.id ] === false ? 'Нет' : 'Не заполнено')
            break
          case 'number':
            value = parseFloat(this.obj.extra['f' + field.id ])
            break
          default:
            value = this.obj.extra['f' + field.id ]
        }
      } catch {
        value = 'Не заполнено'
      }
      return value
    },
    getClass: function (value) {
      if (value > 0 || value === 'оплачено') return 'd-green'
      else if (value < 0 || value === 'не оплачено') return 'd-red'
      else return ''
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.getCharges()
    },
    getCharges: function () {
      this.dataLoading = true
      let options = {
        id: this.obj.id,
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      if (options.id) {
        this.$store.dispatch('UINS_GET_BY_OWNER', options).then(() => {
          this.dataLoading = false
          if (this.$store.getters.uinsStatus === 'success') {
            this.listData = this.$store.getters.uins
          } else {
            this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки начислений')
          }
        }, (err) => {
          this.dataLoading = false
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки начислений' + err)
        })
      } else {
        this.dataLoading = false
      }
    },
    makeReceipt: function (uin) {
      this.$store.dispatch('UINS_SET', uin).then(() => {
        this.$router.push('/charges/receipt/')
      })
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.owner
      if (this.obj.id === undefined) this.backButton()
      else if (this.obj.id !== null) {
        this.getOwner()
        this.$store.dispatch('OWNER_FIELDS_GET').then(() => {
          if (this.$store.getters.ownerFieldsStatus === 'success') {
            this.fields = this.$store.getters.ownerFields
            this.fields.forEach(function (field) {
              if (this.obj.extra['f' + field.id] === undefined) this.obj.extra['f' + field.id] = null
            }, this)
          } else {
            this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
          }
        }, (err) => {
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
        })
        this.getCharges()
      }
    } catch (e) {
      this.backButton()
    }
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>
</style>
