<template>
  <br>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created: function () {
    this.$store.dispatch('AUTH_LOGOUT')
      .then(() => {
        this.$router.push('/login/')
      })
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
