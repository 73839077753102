<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Квитанция</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click.stop="downloadPDF()" v-on="on">
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
        <span>Скачать PDF</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click.stop="print()" v-on="on" class="ml-2">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        <span>Напечатать</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <div id="receipt">
            <v-row class="bb">
              <v-col cols="3" xl="3" md="4" sm="5">
                <img :src="obj.qr" alt="QR код" class="mt-4"/>
              </v-col>
              <v-col cols="9" xl="9" md="8" sm="7">
                <h1 class="mb-4 mt-2">{{ obj.uin}} {{ obj.purpose }}</h1>
                <p><b>Плательщик:</b> {{ obj.last_name }} {{ obj.name }} {{ obj.mid_name }}</p>
                <p><b>Участок:</b> {{ obj.clh }}</p>
                <p><b>Адрес:</b> {{ obj.address }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p><b>Получатель платежа:</b> {{ obj.payee }}</p>
                <p><b>ИНН/КПП:</b> {{ obj.inn }}/{{ obj.kpp }}</p>
                <p>
                  <b>Счёт:</b> {{ obj.account }}<br />
                  в банке {{ obj.bank }},<br />
                  БИК {{ obj.bic }}<br />
                  корр. счёт {{ obj.corresp_account }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="obj.type === 'электроэнергия'">
              <v-col>
                <p>
                  <b>Расчёт: </b>
                  <template v-if="obj.extra.diff > obj.extra.rate">
                    {{ obj.extra.rate }}×{{ obj.tariff.price_norm }} + {{ obj.extra.diff - obj.extra.rate }}×{{ obj.tariff.price_over }} = {{ (obj.extra.rate * obj.tariff.price_norm).toFixed(2) }} + {{ ((obj.extra.diff - obj.extra.rate) *  obj.tariff.price_over).toFixed(2) }} = {{ obj.amount }}
                  </template>
                  <template v-else>
                      {{ obj.extra.diff }}×{{ obj.tariff.price_norm }} = {{ obj.amount }}
                  </template>
                </p>
              </v-col>
            </v-row>
            <v-row v-else-if="obj.type === 'членские'">
              <v-col>
                <p>
                  <b>Расчёт: </b>
                  {{ obj.extra.s }}×{{ obj.extra.amount }} = {{ obj.amount }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <table class="r-table">
                  <template v-if="obj.type === 'электроэнергия'">
                    <thead>
                      <tr>
                        <th>Счётчик</th>
                        <th>Модем</th>
                        <th>Начальные показания, кВт⋅ч</th>
                        <th>Конечные показания, кВт⋅ч</th>
                        <th>Норма, кВт⋅ч</th>
                        <th>Объём, кВт⋅ч</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(counter, index) in obj.extra.counters" :key="counter">
                        <td>{{ counter }}</td>
                        <td>{{ obj.extra.modems[index] }}</td>
                        <td>{{ obj.extra.min_value[index] }}</td>
                        <td>{{ obj.extra.max_value[index] }}</td>
                        <td>{{ obj.extra.rate }}</td>
                        <td>{{ obj.extra.diffs[index] }}</td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-else-if="obj.type === 'членские'">
                    <thead>
                      <tr>
                        <th>Площадь участка, м²</th>
                        <th>Начисление, ₽</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ obj.extra.s }}</td>
                        <td>{{ obj.extra.amount }}</td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p><b>К оплате:</b> {{ obj.amount }} ₽</p>
              </v-col>
              <v-col>
                <p class="text-right"><b>Оплатить до:</b> {{ $moment(new Date(obj.date_till)).format('DD.MM.YYYY') }}</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      obj: {
        extra: {}
      }
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('UINS_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    getChargeByUIN: function () {
      this.$store.dispatch('UINS_GET_UIN', this.$store.getters.uin).then(() => {
        if (this.$store.getters.uinsStatus === 'success') {
          this.obj = this.$store.getters.uin
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    downloadPDF: function () {
      window.open('/api/uin/' + this.obj.uin + '/pdf', '_blank')
    },
    print: function () {
      window.print()
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.uin
      if (this.obj.uin === undefined) this.backButton()
      else if (this.obj.uin !== null) {
        this.getChargeByUIN()
      }
      this.$root.setTitle('Квитанция ' + this.obj.uin)
    } catch (e) {
      this.backButton()
    }
  }
}
</script>

<style scoped>
  .bb {
    border-bottom: 1px solid #000;
  }
  .r-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .r-table th,
  .r-table td {
    padding: .25rem;
    border: 1px solid black;
  }
  .r-table td {
    text-align: right;
  }
  @media print {
    #receipt {
      z-index: 10;
      background-color: white;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      padding: 15px;
    }
  }
</style>
