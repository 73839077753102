<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Изменение тарифа</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="editTariff()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Сохранить</span>
      </v-tooltip>
    </v-app-bar>
    <v-container @keyup.enter="editTariff()">
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-form lazy-validation ref="tariffForm" v-model="formValid">
            <v-menu v-model="dateSinceMenu" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable
                  :value="dateSince"
                  label="Первый день действия тарифа"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="obj.date_since = null"
                  :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="obj.date_since"
                :max="obj.date_till"
                :min="$moment().add(1,'days').format('YYYY-MM-DD')"
                @change="dateSinceMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-menu v-model="dateTillMenu" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable
                  :value="dateTill"
                  label="Последний день действия тарифа"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="obj.date_till = null"
                  :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="obj.date_till"
                :min="obj.date_since"
                @change="dateTillMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field label="Стоимость кВт в пределах нормы"
              v-model.trim="obj.price_norm"
              autocomplete
              @keypress="priceNorm"
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-text-field label="Стоимость кВт сверх нормы"
              v-model.trim="obj.price_over"
              autocomplete
              @keypress="priceOver"
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      obj: {},
      formValid: false,
      dateSinceMenu: false,
      dateTillMenu: false
    }
  },
  computed: {
    dateSince: function () {
      return this.obj.date_since ? this.$moment(this.obj.date_since).format('DD.MM.YYYY') : ''
    },
    dateTill: function () {
      return this.obj.date_till ? this.$moment(this.obj.date_till).format('DD.MM.YYYY') : ''
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('TARIFF_CLEAN')
      this.$router.go(-1)
    },
    editTariff: function () {
      if (this.$refs.tariffForm.validate()) {
        this.$store.dispatch('TARIFF_UPDATE', this.obj).then(() => {
          if (this.$store.getters.tariffsStatus === 'success') {
            this.$store.commit('SNACKBAR_SET', 'Сохранено успешно')
            this.backButton()
          }
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
          this.backButton()
        })
      }
    },
    getTariff: function () {
      this.$store.dispatch('TARIFF_GET_TARIFF', this.$store.getters.tariff).then(() => {
        if (this.$store.getters.tariffsStatus === 'success') {
          this.obj = this.$store.getters.tariff
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    priceNorm: function (e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.obj.price_norm.indexOf('.') !== -1)) {
        e.preventDefault()
      }
      if (this.obj.price_norm !== null && this.obj.price_norm.indexOf('.') > -1 && (this.obj.price_norm.split('.')[1].length > 1)) {
        e.preventDefault()
      }
    },
    priceOver: function (e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.obj.price_over.indexOf('.') !== -1)) {
        e.preventDefault()
      }
      if (this.obj.price_over !== null && this.obj.price_over.indexOf('.') > -1 && (this.obj.price_over.split('.')[1].length > 1)) {
        e.preventDefault()
      }
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.tariff
      if (this.obj.id !== null) this.getTariff()
      this.$root.setTitle('Изменение тарифа')
    } catch (e) {
      this.backButton()
    }
  }
}
</script>

<style scoped>

</style>
