<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Учёт электроэнергии</v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Найти"
        @input="updateProps()"
      ></v-text-field>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="listData.data"
        :options.sync="listOptions"
        :server-items-length="listData.total"
        :loading="dataLoading"
        :footer-props="listFooterProps"
        @update:options="updateProps()"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <text-highlight :queries="searchArray">{{ item.id }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.number }}</text-highlight>
            </td>
            <td>
              <a @click="viewShare(item.share_id)"><text-highlight :queries="searchArray">{{ item.clh }}</text-highlight></a>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(new Date(item.time)).format('DD.MM.YYYY HH:mm:ss') }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.counter_value }}</text-highlight>
            </td>
            <td>
              <template v-if="item.status">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn icon class="pa-2"><text-highlight :queries="searchArray">{{ item.status.status }}</text-highlight></v-btn>
                    </span>
                  </template>
                  <v-list dense>
                    <v-subheader>
                      <span class="d-inline-block text-truncate">
                          Информация
                      </span>
                    </v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Описание</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.description }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Версия оборудования</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.hardware_version }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Версия ПО</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.software_version }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Дата производства</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.produce_date }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Отношение сигнал/шум</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.snr }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Уровень сигнала</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.rssi }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader>
                      <span class="d-inline-block text-truncate">
                          Поверка
                      </span>
                    </v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Дата поверки</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.verification.date }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Истекает</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.verification.expires }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Интервал поверки</v-list-item-title>
                        <v-list-item-subtitle>{{ item.status.info.verification.interval }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                Не получен
              </template>
            </td>
            <td>
              <v-btn icon @click.stop="showBottomsheet(item.id)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="bottomSheetVisible">
        <v-list>
          <v-list-item @click="viewObj()">
            <v-list-item-avatar>
              <v-icon>mdi-eye-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Посмотреть</v-list-item-title>
          </v-list-item>
          <!--
          <v-list-item @click="editObj()">
            <v-list-item-avatar>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Изменить</v-list-item-title>
          </v-list-item>
          -->
        </v-list>
      </v-bottom-sheet>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      listHeaders: [
        { text: 'Счётчик', value: 'id' },
        { text: 'Номер', value: 'number' },
        { text: 'К-Л-Д', value: 'clh' },
        { text: 'Дата', value: 'time' },
        { text: 'Показания ПУ', value: 'counter_value' },
        { text: 'Статус', value: 'status' },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: ''
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    },
    bottomSheetVisible: {
      get: function () {
        return this.$store.getters.isElectricValuesBottomsheetOpen
      },
      set: function () {
        this.$store.commit('ELECTRIC_VALUES_BOTTOMSHEET_CLOSE')
        this.$store.commit('ELECTRIC_VALUES_BOTTOMSHEET_CLEAN')
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      let options = {
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      this.$store.dispatch('ELECTRIC_VALUES_GET_LAST', options).then(() => {
        this.dataLoading = false
        this.listData = []
        if (this.$store.getters.electricValuesStatus === 'success') {
          this.listData = this.$store.getters.electricValues
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.loadData()
    },
    showBottomsheet: function (objId) {
      this.$store.dispatch('ELECTRIC_VALUES_BOTTOMSHEET_OPEN', objId)
    },
    viewObj: function () {
      this.$store.commit('ELECTRIC_VALUES_BOTTOMSHEET_CLOSE')
      this.$router.push('/electricity-accounting/view/')
    },
    editObj: function () {
      this.$store.commit('ELECTRIC_VALUES_BOTTOMSHEET_CLOSE')
      this.$router.push('/electricity-accounting/edit/')
    },
    viewShare: function (shareId) {
      this.$store.dispatch('SHARES_SET', { id: shareId, extra: {}, owners: [] }).then(() => {
        this.$router.push('/shares/view/')
      })
    }
  },
  mounted: function () {
    this.$root.setTitle('Учёт электроэнергии')
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>

</style>
