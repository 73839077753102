import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  owners: [],
  owner: {
    relatives: []
  },
  bottomsheetOpened: false
}

const getters = {
  ownersStatus: state => state.status,
  isOwnersBottomsheetOpen: state => !!state.bottomsheetOpened,
  owners: state => state.owners,
  owner: state => state.owner
}

const actions = {
  ['OWNERS_GET']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/owners', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNERS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNERS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNERS_ERROR')
          resolve(err)
        })
    })
  },
  ['OWNERS_SEARCH_GET']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/owners/search', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNERS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNERS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNERS_ERROR')
          resolve(err)
        })
    })
  },
  ['OWNERS_UPDATE']: ({ commit, dispatch }, owner) => {
    return new Promise((resolve, reject) => {
      commit('OWNERS_UPDATE')
      apiCall({ url: '/api/owners/edit', data: owner, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNERS_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNERS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNERS_ERROR', err)
          reject(err)
        })
    })
  },
  ['OWNERS_REMOVE']: ({ commit, dispatch }, owner) => {
    return new Promise((resolve, reject) => {
      commit('OWNERS_REMOVE')
      apiCall({ url: '/api/owners/del', data: owner, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNERS_REMOVE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNERS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNERS_ERROR', err)
          reject(err)
        })
    })
  },
  ['OWNERS_GET_OWNER']: ({ commit, dispatch }, owner) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/owners/' + owner.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNERS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNERS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNERS_ERROR')
          resolve(err)
        })
    })
  },
  ['OWNERS_SET']: ({ commit }, user) => {
    return new Promise((resolve) => {
      commit('OWNERS_SET', user)
      resolve()
    })
  },
  ['OWNERS_BOTTOMSHEET_OPEN']: ({ commit }, ownerId) => {
    return new Promise((resolve) => {
      commit('OWNERS_BOTTOMSHEET_OPEN', ownerId)
      resolve()
    })
  }
}

const mutations = {
  ['OWNERS_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['OWNERS_REMOVE']: (state) => {
    state.status = 'removing'
  },
  ['OWNERS_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['OWNERS_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['OWNERS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.owners = resp.owners || []
    state.owner = resp.owner || {
      relatives: []
    }

  },
  ['OWNERS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['OWNERS_SET']: (state, owner) => {
    state.owner = owner
  },
  ['OWNERS_BOTTOMSHEET_OPEN']: (state, ownerId) => {
    state.bottomsheetOpened = true
    state.owner = {
      id: ownerId,
      extra: {},
      relatives: []
    }
  },
  ['OWNERS_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['OWNERS_BOTTOMSHEET_CLEAN']: (state) => {
    state.owner = {
      relatives: []
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
