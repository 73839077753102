<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Изменение садовода</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="editOwner()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Сохранить</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-form lazy-validation ref="ownerForm" v-model="formValid">
            <v-text-field label="Фамилия"
              v-model.trim="obj.last_name"
              autocomplete="family-name"
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-text-field label="Имя"
              v-model.trim="obj.name"
              autocomplete="given-name"
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-text-field label="Отчество"
              v-model.trim="obj.mid_name"
              autocomplete="additional-name">
            </v-text-field>
            <v-text-field label="Паспорт"
              v-model.trim="obj.passport">
            </v-text-field>
            <v-text-field label="Адрес"
              v-model.trim="obj.address"
              autocomplete="street-address">
            </v-text-field>
            <v-text-field label="Телефон"
              v-model.trim="obj.tel"
              autocomplete="tel">
            </v-text-field>
            <v-text-field label="Email"
              v-model.trim="obj.email"
              autocomplete="email">
            </v-text-field>
            <template v-for="field in fields">
              <v-text-field v-if="field.type === 'text'"
                :key="field.id"
                :label="field.description"
                v-model.trim="obj.extra['f' + field.id]">
              </v-text-field>
              <v-textarea v-if="field.type === 'textarea'"
                :key="field.id"
                :label="field.description"
                v-model.trim="obj.extra['f' + field.id]"
                auto-grow>
              </v-textarea>
              <v-switch v-if="field.type === 'checkbox'"
                :key="field.id"
                :label="field.description"
                v-model.trim="obj.extra['f' + field.id]">
              </v-switch>
              <v-text-field v-if="field.type === 'number'"
                type="number"
                :key="field.id"
                :label="field.description"
                v-model.number="obj.extra['f' + field.id]">
              </v-text-field>
            </template>
            <v-data-table
              :headers="headers"
              :items="obj.relatives"
              sort-by="name"
              hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="relativeEditDialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on">
                          Добавить родственника
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Родственник</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="relativeItem.name"
                                  label="ФИО"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="relativeItem.tel"
                                  label="Телефон"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="relativeEditClose()">Отменить</v-btn>
                          <v-btn text @click="relativeEditSave()">Сохранить</v-btn>
                        </v-card-actions>
                      </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-dialog v-model="relativeDeleteDialog" max-width="300px">
                  <v-card>
                    <v-card-text class="text-center py-6">Удалить информацию о родственнике?</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="relativeDeleteClose()">Нет</v-btn>
                      <v-btn text @click="relativeDeleteConfirm()">Да</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editRelative(item)">mdi-square-edit-outline</v-icon>
                <v-icon small @click="deleteRelative(item)">mdi-trash-can-outline</v-icon>
              </template>
            </v-data-table>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      obj: {
        extra: {}
      },
      formValid: false,
      fields: [],
      relativeEditDialog: false,
      relativeDeleteDialog: false,
      headers: [
        { text: 'ФИО', value: 'name' },
        { text: 'Телефон', value: 'tel' },
        { text: '', value: 'actions', sortable: false, filterable: false },
      ],
      relativeEditedIndex: -1,
      relativeItem: {
        id: null,
        name: '',
        tel: ''
      },
      relativeDefaultItem: {
        id: null,
        name: '',
        tel: ''
      }
    }
  },
  computed: {
  },
  watch: {
    relativeEditDialog: function (val) {
      val || this.relativeEditClose()
    },
    relativeDeleteDialog: function (val) {
      val || this.relativeDeleteClose()
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('OWNERS_BOTTOMSHEET_CLOSE')
      this.$store.commit('OWNERS_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    editOwner: function () {
      if (this.$refs.ownerForm.validate()) {
        this.$store.dispatch('OWNERS_UPDATE', this.obj).then(() => {
          if (this.$store.getters.ownersStatus === 'success') {
            this.$store.commit('SNACKBAR_SET', 'Сохранено успешно')
            this.backButton()
          }
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
          this.backButton()
        })
      }
    },
    getOwner: function () {
      this.$store.dispatch('OWNERS_GET_OWNER', this.$store.getters.owner).then(() => {
        if (this.$store.getters.ownersStatus === 'success') {
          this.obj = this.$store.getters.owner
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    editRelative: function (item) {
      this.relativeEditedIndex = this.obj.relatives.indexOf(item)
      this.relativeItem = JSON.parse(JSON.stringify(item))
      this.relativeEditDialog = true
    },
    deleteRelative: function (item) {
      this.relativeEditedIndex = this.obj.relatives.indexOf(item)
      this.relativeItem = JSON.parse(JSON.stringify(item))
      this.relativeDeleteDialog = true
    },
    relativeDeleteConfirm: function () {
      this.obj.relatives.splice(this.relativeEditedIndex, 1)
      this.relativeDeleteClose()
    },
    relativeEditClose: function () {
      this.relativeEditDialog = false
      this.$nextTick(() => {
        this.relativeItem = JSON.parse(JSON.stringify(this.relativeDefaultItem))
        this.relativeEditedIndex = -1
      })
    },
    relativeDeleteClose: function () {
      this.relativeDeleteDialog = false
      this.$nextTick(() => {
        this.relativeItem = JSON.parse(JSON.stringify(this.relativeDefaultItem))
        this.relativeEditedIndex = -1
      })
    },
    relativeEditSave: function () {
      if (this.relativeEditedIndex > -1) {
        this.obj.relatives[this.relativeEditedIndex] = JSON.parse(JSON.stringify(this.relativeItem))
      } else if (this.relativeItem.name !== '') {
        this.obj.relatives.push(this.relativeItem)
      }
      this.relativeEditClose()
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.owner
      if (this.obj.id === undefined) this.backButton()
      else if (this.obj.id !== null) {
        this.getOwner()
        this.$store.dispatch('OWNER_FIELDS_GET').then(() => {
          if (this.$store.getters.ownerFieldsStatus === 'success') {
            this.fields = this.$store.getters.ownerFields
            this.fields.forEach(function (field) {
              if (this.obj.extra['f' + field.id] === undefined) this.obj.extra['f' + field.id] = null
            }, this)
          } else {
            this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
          }
        }, (err) => {
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
        })
      }
      this.$root.setTitle('Изменение садовода')
    } catch (e) {
      this.backButton()
    }
  }
}
</script>

<style scoped>

</style>
