<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Название</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="newGroup()" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Кнопка</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      12345
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    }
  },
  methods: {
  },
  mounted: function () {
    if (this.$store.getters.userRole === 'owner') this.$router.push('/electricity-control/')
    else this.$router.push('/owners/')
  }
}
</script>

<style scoped>

</style>
