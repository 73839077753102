<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Дополнительные поля</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="newField()" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Новое поле</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Найти"
        ></v-text-field>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="fields"
        :options.sync="listOptions"
        :loading="dataLoading"
        :footer-props="listFooterProps"
        :search="search"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <text-highlight :queries="[search]">{{ item.description }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="[search]">{{ fieldTypes[item.type] }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="[search]">{{ !!item.show_in_table ? 'да' : 'нет' }}</text-highlight>
            </td>
            <td>
              <v-btn icon @click.stop="showBottomsheet(item.id)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="bottomSheetVisible">
        <v-list>
          <v-list-item @click="editObj()">
            <v-list-item-avatar>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Изменить</v-list-item-title>
          </v-list-item>
          <v-list-item @click="removeObjConfirm()">
            <v-list-item-avatar>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Удалить</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-dialog v-model="dialogVisible" max-width="300">
        <v-card>
          <v-card-text class="text-center py-6">Удалить поле?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="removeObj()">Да</v-btn>
            <v-btn text @click="removeClean()">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      fields: [],
      dataLoading: false,
      listHeaders: [
        { text: 'Описание', value: 'description' },
        { text: 'Тип', value: 'type' },
        { text: 'Показывать в списке', value: 'show_in_table' },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['description'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: '',
      fieldTypes: {
        text: 'Однострочное поле',
        textarea: 'Многострочное поле',
        checkbox: 'Флажок',
        number: 'Число'
      }
    }
  },
  computed: {
    bottomSheetVisible: {
      get: function () {
        return this.$store.getters.isShareFieldsBottomsheetOpen
      },
      set: function () {
        this.$store.commit('SHARE_FIELDS_BOTTOMSHEET_CLOSE')
        this.$store.commit('SHARE_FIELDS_BOTTOMSHEET_CLEAN')
      }
    },
    dialogVisible: {
      get: function () {
        return this.$store.getters.isDialogOpen
      },
      set: function () {
        this.$store.commit('DIALOG_CLOSE')
      }
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('SHARE_FIELDS_CLEAN')
      this.$router.go(-1)
    },
    loadData: function () {
      this.dataLoading = true
      this.$store.dispatch('SHARE_FIELDS_GET').then(() => {
        this.dataLoading = false
        if (this.$store.getters.shareFieldsStatus === 'success') {
          this.fields = this.$store.getters.shareFields
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    showBottomsheet: function (objId) {
      this.$store.dispatch('SHARE_FIELDS_BOTTOMSHEET_OPEN', objId)
    },
    newField: function () {
      this.$store.dispatch('SHARE_FIELDS_SET', { id: null }).then(() => {
        this.$router.push('/shares/fields/edit/')
      })
    },
    editObj: function () {
      this.$store.commit('SHARE_FIELDS_BOTTOMSHEET_CLOSE')
      this.$router.push('/shares/fields/edit/')
    },
    removeClean: function () {
      this.dialogVisible = false
      this.$store.commit('SHARE_FIELDS_BOTTOMSHEET_CLEAN')
    },
    removeObj: function () {
      this.$store.commit('DIALOG_CLOSE')
      this.$store.dispatch('SHARE_FIELDS_REMOVE', { id: this.$store.getters.shareField.id }).then(() => {
        if (this.$store.getters.shareFieldsStatus === 'success') {
          this.loadData()
          this.$store.commit('SNACKBAR_SET', 'Удалено успешно')
        } else {
          this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка')
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка. ' + err)
      }).then(() => {
        this.$store.commit('SHARE_FIELDS_BOTTOMSHEET_CLOSE')
        this.$store.commit('SHARE_FIELDS_BOTTOMSHEET_CLEAN')
      })
    },
    removeObjConfirm: function () {
      this.$store.commit('SHARE_FIELDS_BOTTOMSHEET_CLOSE')
      this.$store.dispatch('DIALOG_OPEN', this.$store.getters.shareField)
    }
  },
  mounted: function () {
    this.loadData()
    this.$root.setTitle('Дополнительные поля')
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>

</style>
