import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  electricValues: [],
  counter: {
    status: {
      info: {
        verification: {}
      },
      status: ''
    }
  },
  bottomsheetOpened: false
}

const getters = {
  electricValuesStatus: state => state.status,
  isElectricValuesBottomsheetOpen: state => !!state.bottomsheetOpened,
  electricValues: state => state.electricValues,
  electricValuesCounter: state => state.counter,
}

const actions = {
  ['ELECTRIC_VALUES_GET_LAST']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/electric-values', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('ELECTRIC_VALUES_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('ELECTRIC_VALUES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('ELECTRIC_VALUES_ERROR')
          resolve(err)
        })
    })
  },
  ['ELECTRIC_VALUES_GET_COUNTER']: ({ commit, dispatch }, counter) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/electric-values/' + counter.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('ELECTRIC_VALUES_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('ELECTRIC_VALUES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('ELECTRIC_VALUES_ERROR')
          resolve(err)
        })
    })
  },
  ['ELECTRIC_VALUES_GET_VALUES']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/electric-values/' + options.id + '/values', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('ELECTRIC_VALUES_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('ELECTRIC_VALUES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('ELECTRIC_VALUES_ERROR')
          resolve(err)
        })
    })
  },
  ['ELECTRIC_VALUES_BOTTOMSHEET_OPEN']: ({ commit }, counterId) => {
    return new Promise((resolve) => {
      commit('ELECTRIC_VALUES_BOTTOMSHEET_OPEN', counterId)
      resolve()
    })
  }
}

const mutations = {
  ['ELECTRIC_VALUES_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.electricValues = resp.electricValues || []
    state.counter = resp.counter || {
      status: {
        info: {
          verification: {}
        },
        status: ''
      }
    }
  },
  ['ELECTRIC_VALUES_ERROR']: (state) => {
    state.status = 'error'
  },
  ['ELECTRIC_VALUES_BOTTOMSHEET_OPEN']: (state, counterId) => {
    state.bottomsheetOpened = true
    state.counter.id = counterId
  },
  ['ELECTRIC_VALUES_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['ELECTRIC_VALUES_BOTTOMSHEET_CLEAN']: (state) => {
    state.counter = {
      status: {
        info: {
          verification: {}
        },
        status: ''
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
