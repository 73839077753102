<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Изменение дополнительного поля</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="editField()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Сохранить</span>
      </v-tooltip>
    </v-app-bar>
    <v-container @keyup.enter="editField()">
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-form lazy-validation ref="fieldForm" v-model="formValid">
            <v-text-field label="Описание поля"
              v-model.trim="obj.description"
              autocomplete
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-select label="Тип поля"
              v-model="obj.type"
              :items="fieldTypes"
              item-text="name"
              item-value="id"
              value="null">
            </v-select>
            <v-switch label="Показывать в списке"
              v-model="obj.show_in_table"
              inset>
            </v-switch>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      obj: {},
      formValid: false,
      fieldTypes: [
        { id: 'text', name: 'Однострочное поле' },
        { id: 'textarea', name: 'Многострочное поле' },
        { id: 'checkbox', name: 'Флажок' },
        { id: 'number', name: 'Число' }
      ]
    }
  },
  computed: {
  },
  methods: {
    backButton: function () {
      this.$store.commit('SHARE_FIELDS_CLEAN')
      this.$router.go(-1)
    },
    editField: function () {
      if (this.$refs.fieldForm.validate()) {
        this.$store.dispatch('SHARE_FIELDS_UPDATE', this.obj).then(() => {
          if (this.$store.getters.shareFieldsStatus === 'success') {
            this.$store.commit('SNACKBAR_SET', 'Сохранено успешно')
            this.backButton()
          }
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
          this.backButton()
        })
      }
    },
    getField: function () {
      this.$store.dispatch('SHARE_FIELDS_GET_FIELD', this.$store.getters.shareField).then(() => {
        if (this.$store.getters.shareFieldsStatus === 'success') {
          this.obj = this.$store.getters.shareField
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.shareField
      if (this.obj.id !== null) this.getField()
      this.$root.setTitle('Изменение дополнительного поля')
    } catch (e) {
      this.backButton()
    }
  }
}
</script>

<style scoped>

</style>
