<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Изменение участка</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="editShare()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Сохранить</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-form lazy-validation ref="shareForm" v-model="formValid">
            <v-row>
              <v-col>
                <v-text-field label="Куст"
                  v-model.trim="obj.cluster"
                  :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Линия"
                  v-model.trim="obj.line"
                  :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Дача"
                  v-model.trim="obj.house"
                  :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
                </v-text-field>
              </v-col>
            </v-row>
            <v-text-field label="Кадастровый номер"
              v-model.trim="obj.cadastral_number">
            </v-text-field>
            <v-text-field label="Площадь"
              v-model.trim="obj.s">
            </v-text-field>
            <v-text-field label="Номер счётчика"
              v-model.trim="obj.modem_number">
            </v-text-field>
            <v-switch label="Запрет управления электропитанием"
              v-model.trim="obj.pm_prohibit">
            </v-switch>
            <v-switch label="Подключен ли участок к электросети"
              v-model.trim="obj.p_connected">
            </v-switch>
            <v-textarea label="Комментарий"
              v-model.trim="obj.comment"
              auto-grow>
            </v-textarea>
            <template v-for="field in fields">
              <v-text-field v-if="field.type === 'text'"
                :key="field.id"
                :label="field.description"
                v-model.trim="obj.extra['f' + field.id]">
              </v-text-field>
              <v-textarea v-if="field.type === 'textarea'"
                :key="field.id"
                :label="field.description"
                v-model.trim="obj.extra['f' + field.id]"
                auto-grow>
              </v-textarea>
              <v-switch v-if="field.type === 'checkbox'"
                :key="field.id"
                :label="field.description"
                v-model.trim="obj.extra['f' + field.id]">
              </v-switch>
              <v-text-field v-if="field.type === 'number'"
                type="number"
                :key="field.id"
                :label="field.description"
                v-model.number="obj.extra['f' + field.id]">
              </v-text-field>
            </template>
            <v-data-table
              :key="JSON.stringify(obj.owners)"
              :headers="headers"
              :items="obj.owners"
              :options.sync="listOptions"
              hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="ownerEditDialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on">
                          Добавить владельца
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ ownerEditedIndex === -1 ? 'Добавить' : 'Изменить' }} владельца</span>
                        </v-card-title>
                        <v-card-text>
                          <template v-if="ownerEditedIndex === -1">
                            <v-text-field
                              v-model="ownerSearch"
                              append-icon="mdi-magnify"
                              label="Найти"
                              @input="updateOwnersProps()"
                            ></v-text-field>
                            <v-data-table
                              v-model="selectedOwner"
                              :headers="dialogHeaders"
                              :items="owners.data"
                              :options.sync="dialogListOptions"
                              :server-items-length="owners.total"
                              :loading="dialogDataLoading"
                              :footer-props="dialogListFooterProps"
                              single-select
                              show-select
                              @update:options="updateOwnersProps()">
                              <template v-slot:item.full_name="{ item }">
                                {{ item.last_name }} {{ item.name }} {{ item.mid_name }}
                              </template>
                            </v-data-table>
                          </template>
                          <template v-else>
                            <p>{{ ownerItem.last_name }} {{ ownerItem.name }} {{ ownerItem.mid_name }}</p>
                          </template>
                          <v-menu v-model="dateSinceMenu" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field clearable
                                :value="dateSince"
                                label="Дата начала владения"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="ownerItem.pivot.owned_since = null"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="ownerItem.pivot.owned_since"
                              @change="dateSinceMenu = false"
                            ></v-date-picker>
                          </v-menu>
                          <v-switch label="Последний владелец"
                            v-model.trim="lastOwner"
                            @change="lastOwnerChange()">
                          </v-switch>
                          <v-menu v-show="!lastOwner" v-model="dateTillMenu" :close-on-content-click="false" max-width="290">
                            <template v-show="!lastOwner" v-slot:activator="{ on, attrs }">
                              <v-text-field v-show="!lastOwner"
                                clearable
                                :value="dateTill"
                                label="Дата окончания владения"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="ownerItem.pivot.owned_till = null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-show="!lastOwner"
                              v-model="ownerItem.pivot.owned_till"
                              @change="dateTillMenu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="ownerEditClose()">Отменить</v-btn>
                          <v-btn :disabled="(!selectedOwner.length && ownerEditedIndex === -1) || !ownerItem.pivot.owned_since || !(lastOwner || !!ownerItem.pivot.owned_till)" text @click="ownerEditSave()">Сохранить</v-btn>
                        </v-card-actions>
                      </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-dialog v-model="ownerDeleteDialog" max-width="300px">
                  <v-card>
                    <v-card-text class="text-center py-6">Удалить владельца из списка владельцев?</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="ownerDeleteClose()">Нет</v-btn>
                      <v-btn text @click="ownerDeleteConfirm()">Да</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:item.full_name="{ item }">
                {{ item.last_name }} {{ item.name }} {{ item.mid_name }}
              </template>
              <template v-slot:item.dates="{ item }">
                {{ item.pivot.owned_since !== '0000-00-00' ? $moment(new Date(item.pivot.owned_since)).format('DD.MM.YYYY') : '00.00.0000' }} — {{ !!item.pivot.owned_till ? $moment(new Date(item.pivot.owned_till)).format('DD.MM.YYYY') : 'н. в.'}}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editOwner(item)">mdi-square-edit-outline</v-icon>
                <v-icon :disabled="!item.pivot.owned_till" small @click="deleteOwner(item)">mdi-trash-can-outline</v-icon>
              </template>
            </v-data-table>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      obj: {
        extra: {},
        owners: []
      },
      formValid: false,
      fields: [],
      ownerEditDialog: false,
      ownerDeleteDialog: false,
      headers: [
        { text: 'Код', value: 'id' },
        { text: 'ФИО', value: 'full_name', sortable: false, filterable: false },
        { text: 'Даты', value: 'dates', sortable: false, filterable: false },
        { text: '', value: 'actions', sortable: false, filterable: false },
      ],
      listOptions: {
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      dialogHeaders: [
        { text: 'Код', value: 'id' },
        { text: 'ФИО', value: 'full_name' },
      ],
      dialogListOptions: {
        itemsPerPage: 5,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      ownerEditedIndex: -1,
      ownerItem: {
        id: null,
        extra: {},
        pivot: {}
      },
      ownerDefaultItem: {
        id: null,
        extra: {},
        pivot: {}
      },
      dateSinceMenu: false,
      dateTillMenu: false,
      lastOwner: false,
      owners: [],
      ownerSearch: '',
      selectedOwner: [],
      dialogDataLoading: false,
      dialogListFooterProps: {
        showFirstLastPage: false,
        itemsPerPageOptions: [5, 10, 20]
      },
    }
  },
  computed: {
    dateSince: function () {
      return this.ownerItem.pivot.owned_since ? this.$moment(this.ownerItem.pivot.owned_since).format('DD.MM.YYYY') : ''
    },
    dateTill: function () {
      return this.ownerItem.pivot.owned_till ? this.$moment(this.ownerItem.pivot.owned_till).format('DD.MM.YYYY') : ''
    }
  },
  watch: {
    ownerEditDialog: function (val) {
      val || this.ownerEditClose()
    },
    ownerDeleteDialog: function (val) {
      val || this.ownerDeleteClose()
    },
    selectedOwner: function (val) {
      try {
        this.ownerItem.id = val[0].id
        this.ownerItem.last_name = val[0].last_name
        this.ownerItem.name = val[0].name
        this.ownerItem.mid_name = val[0].mid_name
      } catch (e) {
        this.ownerItem.id = null
        this.ownerItem.last_name = undefined
        this.ownerItem.name = undefined
        this.ownerItem.mid_name = undefined
      }
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('SHARES_BOTTOMSHEET_CLOSE')
      this.$store.commit('SHARES_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    editShare: function () {
      if (this.$refs.shareForm.validate()) {
        this.$store.dispatch('SHARES_UPDATE', this.obj).then(() => {
          if (this.$store.getters.sharesStatus === 'success') {
            this.$store.commit('SNACKBAR_SET', 'Сохранено успешно')
            this.backButton()
          }
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
          this.backButton()
        })
      }
    },
    getShare: function () {
      this.$store.dispatch('SHARES_GET_SHARE', this.$store.getters.share).then(() => {
        if (this.$store.getters.sharesStatus === 'success') {
          this.obj = this.$store.getters.share
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    editOwner: function (item) {
      this.ownerEditedIndex = this.obj.owners.indexOf(item)
      this.ownerItem = JSON.parse(JSON.stringify(item))
      if (this.ownerItem.pivot.owned_till === null) this.lastOwner = true
      this.ownerEditDialog = true
    },
    deleteOwner: function (item) {
      this.ownerEditedIndex = this.obj.owners.indexOf(item)
      this.ownerItem = JSON.parse(JSON.stringify(item))
      this.ownerDeleteDialog = true
    },
    ownerDeleteConfirm: function () {
      this.obj.owners.splice(this.ownerEditedIndex, 1)
      this.ownerDeleteClose()
    },
    ownerEditClose: function () {
      this.ownerEditDialog = false
      this.$nextTick(() => {
        this.ownerItem = JSON.parse(JSON.stringify(this.ownerDefaultItem))
        this.ownerEditedIndex = -1
        this.ownerSearch = ''
        this.selectedOwner = []
        this.lastOwner = false
      })
    },
    ownerDeleteClose: function () {
      this.ownerDeleteDialog = false
      this.$nextTick(() => {
        this.ownerItem = JSON.parse(JSON.stringify(this.ownerDefaultItem))
        this.ownerEditedIndex = -1
      })
    },
    ownerEditSave: function () {
      if (this.ownerItem.pivot.owned_till === null) {
        this.obj.owners.forEach(function (owner) {
          if (owner.id !== this.ownerItem.id && owner.pivot.owned_till === null) {
            owner.pivot.owned_till = this.ownerItem.pivot.owned_since
          }
        }, this)
      }
      if (this.ownerEditedIndex > -1) {
        this.obj.owners[this.ownerEditedIndex] = JSON.parse(JSON.stringify(this.ownerItem))
      } else if (this.ownerItem.id !== null) {
        this.obj.owners.push(this.ownerItem)
      }
      this.ownerEditClose()
    },
    lastOwnerChange: function () {
      if (this.lastOwner) this.ownerItem.pivot.owned_till = null
    },
    updateOwnersProps: function () {
      if (this.dialogListOptions.sortBy.length === 1) this.dialogListOptions.mustSort = true
      else this.dialogListOptions.mustSort = false
      this.loadOwnersData()
    },
    loadOwnersData: function () {
      this.dialogDataLoading = true
      let options = {
        page: this.dialogListOptions.page,
        itemsPerPage: this.dialogListOptions.itemsPerPage,
        sortBy: this.dialogListOptions.sortBy,
        sortDesc: this.dialogListOptions.sortDesc,
        search: this.ownerSearch
      }
      this.$store.dispatch('OWNERS_SEARCH_GET', options).then(() => {
        this.dialogDataLoading = false
        if (this.$store.getters.ownersStatus === 'success') {
          this.owners = this.$store.getters.owners
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dialogDataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.share
      if (this.obj.id === undefined) this.backButton()
      else if (this.obj.id !== null) {
        this.getShare()
        this.loadOwnersData()
        this.$store.dispatch('SHARE_FIELDS_GET').then(() => {
          if (this.$store.getters.shareFieldsStatus === 'success') {
            this.fields = this.$store.getters.shareFields
            this.fields.forEach(function (field) {
              if (this.obj.extra['f' + field.id] === undefined) this.obj.extra['f' + field.id] = null
            }, this)
          } else {
            this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
          }
        }, (err) => {
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
        })
      }
      this.$root.setTitle('Изменение участка')
    } catch (e) {
      this.backButton()
    }
  }
}
</script>

<style scoped>

</style>
