const state = { opened: false, obj: null }

const getters = {
  isDialogOpen: state => !!state.opened,
  dialogObj: state => state.obj
}

const actions = {
  ['DIALOG_OPEN']: ({ commit }, obj) => {
    return new Promise((resolve) => {
      commit('DIALOG_OPEN', obj)
      resolve(obj)
    })
  }
}

const mutations = {
  ['DIALOG_OPEN']: (state, obj) => {
    state.opened = true
    state.obj = obj
  },
  ['DIALOG_CLOSE']: (state) => {
    state.opened = false
    state.obj = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
