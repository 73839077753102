import apiCall from '../../plugins/api'

const state = { token: localStorage.getItem('token') || '', status: '' }

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
}

const actions = {
  ['AUTH_REQUEST']: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST')
      apiCall({ url: '/api/auth', data: user, method: 'POST' })
        .then(resp => {
          if (resp.token) {
            localStorage.setItem('token', resp.token)
            commit('AUTH_SUCCESS', resp)
            dispatch('ACCOUNT_GET')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR', resp.payload.error)
            localStorage.removeItem('token')
            reject(resp.payload.error)
          }
        })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('token')
          reject(err)
        })
    })
  },
  ['AUTH_LOGOUT']: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_LOGOUT')
      apiCall({ url: '/api/logout', data: '', method: 'POST' })
        .then(resp => {
          commit('AUTH_LOGOUT')
          localStorage.removeItem('token')
          resolve(resp)
        })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('token')
          reject(err)
        })
    })
  }
}

const mutations = {
  ['AUTH_REQUEST']: (state) => {
    state.status = 'loading'
  },
  ['AUTH_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.token = resp.token
  },
  ['AUTH_ERROR']: (state) => {
    state.status = 'error'
    state.token = ''
  },
  ['AUTH_LOGOUT']: (state) => {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
