<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Просмотр счётчика</v-toolbar-title>
      <!--
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click.stop="editCounter()" v-on="on">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Изменить счётчик</span>
      </v-tooltip>
      -->
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-alert type="info">
            <v-row align="center">
              <v-col class="grow">
                Для изменения привязки счётчика воспользуйтесь ЛК Waviot
              </v-col>
              <v-col class="shrink">
                <v-btn @click="gotoWaviot()">Перейти</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-list>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  {{ obj.id }}
              </span>
            </v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Заводской номер</v-list-item-title>
                <v-list-item-subtitle>{{ obj.number }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          <v-subheader>
              <span class="d-inline-block text-truncate">
                  Участки
              </span>
            </v-subheader>
            <v-list-item v-for="(share, index) in obj.shares" :key="'s' + index">
              <v-list-item-content>
                <v-list-item-title>{{ share.cluster }}-{{ share.line }}-{{ share.house }}</v-list-item-title>
                <v-list-item-subtitle><template v-if="share.pivot.used_till === null">Используется</template><template v-else>Использовался</template> с {{ share.pivot.used_since !== '0000-00-00' ? $moment(new Date(share.pivot.used_since)).format('DD.MM.YYYY') : 'незапамятных времён' }} по {{ share.pivot.used_till ? $moment(new Date(share.pivot.used_till)).format('DD.MM.YYYY') : 'настоящее время' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Информация
              </span>
            </v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Описание</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Версия оборудования</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.hardware_version }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Версия ПО</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.software_version }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Дата производства</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.produce_date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Отношение сигнал/шум</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.snr }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Уровень сигнала</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.rssi }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Статус</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.status }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Поверка
              </span>
            </v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Дата поверки</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.verification.date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Истекает</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.verification.expires }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Интервал поверки</v-list-item-title>
                <v-list-item-subtitle>{{ obj.status.info.verification.interval }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Показания
              </span>
            </v-subheader>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Найти"
              @input="updateProps()"
            ></v-text-field>
            <!--
            <v-checkbox
              v-model="onlyValues"
              label="Только полученные показания"
              @change="updateProps()">
            </v-checkbox>
            -->
            <v-data-table
              fixed-header
              :headers="listHeaders"
              :items="listData.data"
              :options.sync="listOptions"
              :server-items-length="listData.total"
              :loading="dataLoading"
              :footer-props="listFooterProps"
              @update:options="updateProps()"
              >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <text-highlight :queries="searchArray">{{ $moment(new Date(item.time)).format('DD.MM.YYYY HH:mm:ss') }}</text-highlight>
                  </td>
                  <td>
                    <text-highlight :queries="searchArray">{{ item.counter_value || 'Не получены' }}</text-highlight>
                  </td>
                  <!--
                  <td>
                    <text-highlight :queries="searchArray">{{ item.status }}</text-highlight>
                  </td>
                  -->
                </tr>
              </template>
            </v-data-table>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      obj: {
        status: {
          info: {
            verification: {}
          },
          status: ''
        }
      },
      listHeaders: [
        { text: 'Дата', value: 'time' },
        { text: 'Показания ПУ', value: 'counter_value' },
        // { text: 'Статус', value: 'status', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['time'],
        sortDesc: [true],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: '',
      onlyValues: false
    }
  },
  computed: {
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('ELECTRIC_VALUES_BOTTOMSHEET_CLOSE')
      this.$store.commit('ELECTRIC_VALUES_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    editCounter: function () {
      this.$router.push('/electricity-accounting/edit/')
    },
    getCounter: function () {
      return new Promise ((resolve, reject) => {
        this.$store.dispatch('ELECTRIC_VALUES_GET_COUNTER', this.$store.getters.electricValuesCounter).then(() => {
          if (this.$store.getters.electricValuesStatus === 'success') {
            this.obj = this.$store.getters.electricValuesCounter
            resolve()
          } else {
            this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
            reject()
          }
        }, (err) => {
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
          reject()
        })
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.getValues()
    },
    getValues: function () {
      this.dataLoading = true
      let options = {
        id: this.obj.id,
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search,
        onlyValues: this.onlyValues
      }
      this.$store.dispatch('ELECTRIC_VALUES_GET_VALUES', options).then(() => {
        this.dataLoading = false
        if (this.$store.getters.electricValuesStatus === 'success') {
          this.listData = this.$store.getters.electricValues
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки истории показаний')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки истории показаний' + err)
      })
    },
    gotoWaviot: function () {
      window.open('https://lk.waviot.ru/', '_blank')
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.electricValuesCounter
      if (this.obj.id === undefined) this.backButton()
      else if (this.obj.id !== null) {
        this.getCounter().then(() => {
          this.getValues()
          this.$root.setTitle('Просмотр счётчика ' + this.obj.number)
        })
      }
    } catch (e) {
      this.backButton()
    }
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>
</style>
