<template>
  <v-app>
    <menu-page></menu-page>
    <router-view></router-view>
    <v-snackbar
      v-model="snackbar"
      top
      app
      timeout.number="10000">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click="snackbar = false" v-bind="attrs">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import MenuPage from './components/MenuPage'

export default {
  props: {
    source: String
  },
  data () {
    return {
      currentPage: 'HomePage',
      snackbar: false,
      snackbarMessage: ''
    }
  },
  methods: {
  },
  created: function () {
    this.$store.watch(state => state.snackbar.message, () => {
      let message = this.$store.getters.snackbarMessage
      if (message !== '') {
        this.snackbar = true
        this.snackbarMessage = this.$store.getters.snackbarMessage
        this.$store.commit('SNACKBAR_SET', '')
      }
    })
  },
  mounted: function () {
  },
  components: {
    MenuPage
  }
}
</script>
