import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  users: [],
  user: {},
  bottomsheetOpened: false
}

const getters = {
  usersStatus: state => state.status,
  isUsersBottomsheetOpen: state => !!state.bottomsheetOpened,
  users: state => state.users
}

const actions = {
  ['USERS_GET']: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/users', data: {}, method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('USERS_SUCCESS', resp)
            resolve(resp)
          } else {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          }
        })
        .catch(err => {
          commit('USERS_ERROR')
          resolve(err)
        })
    })
  },
  ['USERS_UPDATE']: ({ commit, dispatch }, account) => {
    return new Promise((resolve, reject) => {
      commit('USERS_UPDATE')
      apiCall({ url: '/api/account/set', data: account, method: 'POST' })
        .then(resp => {
          if (resp.status === 'accountOK') {
            commit('USERS_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'emailError') {
            commit('USERS_EMAIL_ERROR')
            resolve(resp)
          } else if (resp.status === 'passwordError') {
            commit('USERS_PASSWORD_ERROR')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          } else {
            commit('USERS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('USERS_ERROR', err)
          reject(err)
        })
    })
  },
  ['USERS_REMOVE']: ({ commit, dispatch }, account) => {
    return new Promise((resolve, reject) => {
      commit('USERS_REMOVE')
      apiCall({ url: '/api/account/del', data: account, method: 'POST' })
        .then(resp => {
          if (resp.status === 'accountOK') {
            commit('USERS_REMOVE_SUCCESS')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            resolve(resp)
          } else if (resp.status === 'passwordError') {
            commit('USERS_PASSWORD_ERROR')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          }
        })
        .catch(err => {
          commit('USERS_ERROR', err)
          reject(err)
        })
    })
  },
  ['USERS_SET']: ({ commit }, user) => {
    return new Promise((resolve) => {
      commit('USERS_SET', user)
      resolve()
    })
  },
  ['USERS_BOTTOMSHEET_OPEN']: ({ commit }, user) => {
    return new Promise((resolve) => {
      commit('USERS_BOTTOMSHEET_OPEN', user)
      resolve()
    })
  }
}

const mutations = {
  ['USERS_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['USERS_REMOVE']: (state) => {
    state.status = 'removing'
  },
  ['USERS_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['USERS_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['USERS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.users = resp.users
  },
  ['USERS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['USERS_SET']: (state, user) => {
    state.user = user
  },
  ['USERS_BOTTOMSHEET_OPEN']: (state, user) => {
    state.bottomsheetOpened = true
    state.user = user
  },
  ['USERS_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
