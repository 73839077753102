import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import moment from 'moment'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.prototype.$moment = moment

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
  methods: {
    moment: function () {
      return moment()
    },
    setTitle: function (title) {
      document.title = title + ' — ЛК СНТ «Окское»'
    }
  },
  mounted: function () {
    this.$moment.locale('ru')
  }
}).$mount('#app')
