<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Список садоводов</v-toolbar-title>
      <template v-if="$store.getters.isAdmin">
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="gotoSettings()" v-on="on">
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <span>Настроить поля</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="newOwner()" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Новый садовод</span>
        </v-tooltip>
      </template>
    </v-app-bar>
    <v-container fluid>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Найти"
        @input="updateProps()"
      ></v-text-field>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="listData.data"
        :options.sync="listOptions"
        :server-items-length="listData.total"
        :loading="dataLoading"
        :footer-props="listFooterProps"
        @update:options="updateProps()"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <text-highlight :queries="searchArray">{{ item.id }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.last_name }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.name }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.mid_name }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.address }}</text-highlight>
            </td>
            <td>
              <a :href="'tel:' + item.tel"><text-highlight :queries="searchArray">{{ item.tel }}</text-highlight></a>
            </td>
            <td>
              <p v-for="(share, index) in item.shares" :key="index" class="mb-0">
                <a @click="viewShare(share.id)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <text-highlight :queries="searchArray">{{ share.share }}</text-highlight>
                      </span>
                    </template>
                    <span>В собственности с
                      <template v-if="share.owned_since === '0000-00-00'">незапамятных времён</template>
                      <template v-else>{{ $moment(new Date(share.owned_since)).format('DD.MM.YYYY') }}</template>
                      <br />
                      <template v-if="share.owned_till === null">по настоящее время</template>
                      <template v-else>по {{ $moment(new Date(share.owned_till)).format('DD.MM.YYYY') }}</template>
                    </span>
                  </v-tooltip>
                  <v-tooltip v-if="share.owned_till === null" right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" x-small class="ml-1" color="primary">mdi-check-decagram</v-icon>
                    </template>
                    <span>Владеет сейчас</span>
                  </v-tooltip>
                </a>
              </p>
            </td>
            <td>
              <p v-for="(relative, index) in item.relatives" :key="index" class="mb-0">
                <text-highlight :queries="searchArray">{{ relative.name }}</text-highlight> —
                <a :href="'tel:' + relative.tel"><text-highlight :queries="searchArray">{{ relative.tel }}</text-highlight></a>
              </p>
            </td>
            <td :class="getClass(item.m_debt)">
              <text-highlight :queries="searchArray">{{ item.m_debt }}</text-highlight>
            </td>
            <td :class="getClass(item.t_debt)">
              <text-highlight :queries="searchArray">{{ item.t_debt }}</text-highlight>
            </td>
            <td :class="getClass(item.nt_debt)">
              <text-highlight :queries="searchArray">{{ item.nt_debt }}</text-highlight>
            </td>
            <td :class="getClass(item.e_debt)">
              <text-highlight :queries="searchArray">{{ item.e_debt }}</text-highlight>
            </td>
            <td v-for="(field, index) in fields" :key="index">
                <template v-if="field.type === 'checkbox' && item[field.id] !== null">
                  <text-highlight :queries="searchArray">{{ item[field.id] === 'true' ? 'да' : 'нет' }}</text-highlight>
                </template>
                <template v-else>
                  <text-highlight :queries="searchArray">{{ item[field.id] }}</text-highlight>
                </template>
            </td>
            <td>
              <v-btn icon @click.stop="showBottomsheet(item.id)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="bottomSheetVisible">
        <v-list>
          <v-list-item @click="viewObj()">
            <v-list-item-avatar>
              <v-icon>mdi-eye-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Посмотреть</v-list-item-title>
          </v-list-item>
          <v-list-item @click="editObj()">
            <v-list-item-avatar>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Изменить</v-list-item-title>
          </v-list-item>
          <v-list-item @click="removeObjConfirm()">
            <v-list-item-avatar>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Удалить</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-dialog v-model="dialogVisible" max-width="300">
        <v-card>
          <v-card-text class="text-center py-6">Удалить садовода?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="removeObj()">Да</v-btn>
            <v-btn text @click="removeClean()">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      listHeaders: [
        { text: 'Код', value: 'id' },
        { text: 'Фамилия', value: 'last_name' },
        { text: 'Имя', value: 'name' },
        { text: 'Отчество', value: 'mid_name' },
        { text: 'Адрес', value: 'address' },
        { text: 'Телефон', value: 'tel' },
        { text: 'Участки', value: 'shares'},
        { text: 'Родственники', value: 'relatives' },
        { text: 'Долг ЧЛ', value: 'm_debt' },
        { text: 'Долг Ц', value: 't_debt' },
        { text: 'Долг НЦ', value: 'nt_debt' },
        { text: 'Долг Э', value: 'e_debt' },
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: '',
      fields: []
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    },
    bottomSheetVisible: {
      get: function () {
        return this.$store.getters.isOwnersBottomsheetOpen
      },
      set: function () {
        this.$store.commit('OWNERS_BOTTOMSHEET_CLOSE')
        this.$store.commit('OWNERS_BOTTOMSHEET_CLEAN')
      }
    },
    dialogVisible: {
      get: function () {
        return this.$store.getters.isDialogOpen
      },
      set: function () {
        this.$store.commit('DIALOG_CLOSE')
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      let options = {
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      this.$store.dispatch('OWNERS_GET', options).then(() => {
        this.dataLoading = false
        if (this.$store.getters.ownersStatus === 'success') {
          this.listData = this.$store.getters.owners
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.loadData()
    },
    gotoSettings: function () {
      this.$router.push('/owners/fields')
    },
    showBottomsheet: function (objId) {
      this.$store.dispatch('OWNERS_BOTTOMSHEET_OPEN', objId)
    },
    viewObj: function () {
      this.$store.commit('OWNERS_BOTTOMSHEET_CLOSE')
      this.$router.push('/owners/view/')
    },
    editObj: function () {
      this.$store.commit('OWNERS_BOTTOMSHEET_CLOSE')
      this.$router.push('/owners/edit/')
    },
    removeClean: function () {
      this.dialogVisible = false
      this.$store.commit('OWNERS_BOTTOMSHEET_CLEAN')
    },
    removeObj: function () {
      this.$store.commit('DIALOG_CLOSE')
      this.$store.dispatch('OWNERS_REMOVE', { id: this.$store.getters.owner.id }).then(() => {
        if (this.$store.getters.ownersStatus === 'success') {
          this.loadData()
          this.$store.commit('SNACKBAR_SET', 'Удалено успешно')
        } else {
          this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка')
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка. ' + err)
      }).then(() => {
        this.$store.commit('OWNERS_BOTTOMSHEET_CLOSE')
        this.$store.commit('OWNERS_BOTTOMSHEET_CLEAN')
      })
    },
    removeObjConfirm: function () {
      this.$store.commit('OWNERS_BOTTOMSHEET_CLOSE')
      this.$store.dispatch('DIALOG_OPEN', this.$store.getters.owner)
    },
    newOwner: function () {
      this.$store.dispatch('OWNERS_SET', { id: null, extra: {}, relatives: [] }).then(() => {
        this.$router.push('/owners/edit/')
      })
    },
    viewShare: function (shareId) {
      this.$store.dispatch('SHARES_SET', { id: shareId, extra: {}, owners: [] }).then(() => {
        this.$router.push('/shares/view/')
      })
    },
    getClass: function (value) {
      if (value > 0) return 'd-green'
      else if (value < 0) return 'd-red'
      else return ''
    }
  },
  mounted: function () {
    this.$store.dispatch('OWNER_FIELDS_GET').then(() => {
      if (this.$store.getters.ownerFieldsStatus === 'success') {
        this.$store.getters.ownerFields.forEach(function (field) {
          if (field.show_in_table) {
            this.fields.push({
              id: 'f' + field.id,
              type: field.type
            })
            this.listHeaders.push({
              text: field.description,
              value: 'f' + field.id
            })
          }
        }, this)
        this.listHeaders.push({
          text: '',
          value: 'actions',
          sortable: false,
          filterable: false
        })
        this.$root.setTitle('Список садоводов')
      } else {
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
      }
    }, (err) => {
      err = ''
      this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
    })
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>

</style>
