<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Список значений</v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="listData"
        :loading="dataLoading"
        sort-by="const"
        hide-default-footer
        :options.sync="listOptions"
        >
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editField(item)">mdi-square-edit-outline</v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="configEditDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Изменить значение</span>
          </v-card-title>
          <v-card-text>
            <p>{{ configItem.description }}</p>
            <v-text-field
              clearable
              v-model="configItem.value"
              :label="configItem.const"
              @click:clear="configItem.value = ''"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="configEditClose()">Отменить</v-btn>
            <v-btn text @click="configEditSave()">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      listHeaders: [
        { text: 'Имя константы', value: 'const' },
        { text: 'Описание константы', value: 'description' },
        { text: 'Значение константы', value: 'value' },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        sortBy: ['const'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      configEditDialog: false,
      configEditedIndex: -1,
      configItem: {
        const: null,
        value: ''
      }
    }
  },
  watch: {
    configEditDialog: function (val) {
      val || this.configEditClose()
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      this.$store.dispatch('CONFIG_GET', {}).then(() => {
        this.dataLoading = false
        if (this.$store.getters.configStatus === 'success') {
          this.listData = this.$store.getters.config
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    editField: function (item) {
      this.configEditedIndex = this.listData.indexOf(item)
      Object.assign(this.configItem, item)
      this.configEditDialog = true
    },
    configEditClose: function () {
      this.configEditDialog = false
      this.$nextTick(() => {
        this.configItem = {}
        this.configEditedIndex = -1
      })
    },
    configEditSave: function () {
      this.$store.dispatch('CONFIG_UPDATE', this.configItem).then(() => {
        if (this.$store.getters.configStatus === 'success') {
          this.$store.commit('SNACKBAR_SET', 'Сохранено успешно')
          Object.assign(this.listData[this.configEditedIndex], this.configItem)
          this.configEditClose()
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
        this.configEditClose()
      })
    },
  },
  mounted: function () {
    this.loadData()
    this.$root.setTitle('Список значений')
  }
}
</script>

<style scoped>

</style>
