import Vue from 'vue'
import Router from 'vue-router'
import AccountPage from '@/components/AccountPage'
import ChargesPage from '@/components/ChargesPage'
import ChargesEditForm from '@/components/ChargesEditForm'
import ChargesView from '@/components/ChargesView'
import ChargesReceipt from '@/components/ChargesReceipt'
import ConfigPage from '@/components/ConfigPage'
import ElectricityAccountingPage from '@/components/ElectricityAccountingPage'
import ElectricityAccountingView from '@/components/ElectricityAccountingView'
import ElectricityControlPage from '@/components/ElectricityControlPage'
import EmailConfirmPage from '@/components/EmailConfirmPage'
import HomePage from '@/components/HomePage'
import LoginPage from '@/components/LoginPage'
import LogoutPage from '@/components/LogoutPage'
import MySharesPage from '@/components/MySharesPage'
import OperationsHistoryPage from '@/components/OperationsHistoryPage'
import OwnersFieldsPage from '@/components/OwnersFieldsPage'
import OwnersFieldsEditForm from '@/components/OwnersFieldsEditForm'
import OwnersPage from '@/components/OwnersPage'
import OwnersEditForm from '@/components/OwnersEditForm'
import OwnersView from '@/components/OwnersView'
import PaymentPage from '@/components/PaymentPage'
import PaymentTransactionsPage from '@/components/PaymentTransactionsPage'
import RegisterPage from '@/components/RegisterPage'
import SharesFieldsPage from '@/components/SharesFieldsPage'
import SharesFieldsEditForm from '@/components/SharesFieldsEditForm'
import SharesPage from '@/components/SharesPage'
import SharesEditForm from '@/components/SharesEditForm'
import SharesView from '@/components/SharesView'
import TariffsPage from '@/components/TariffsPage'
import TariffsEditForm from '@/components/TariffsEditForm'
import UsersPage from '@/components/UsersPage'

import store from '../store'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login/')
}

const ifAdmin = (to, from, next) => {
  if (store.getters.isAdmin) {
    next()
    return
  }
  next('/')
}

const ifAccounter = (to, from, next) => {
  if (store.getters.isAccounter) {
    next()
    return
  }
  next('/')
}

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/account/',
      name: 'AccountPage',
      component: AccountPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/charges/',
      name: 'ChargesPage',
      component: ChargesPage,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/charges/edit/',
      name: 'ChargesEditForm',
      component: ChargesEditForm,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/charges/view/',
      name: 'ChargesView',
      component: ChargesView,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/charges/receipt/',
      name: 'ChargesReceipt',
      component: ChargesReceipt,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/config/',
      name: 'ConfigPage',
      component: ConfigPage,
      beforeEnter: ifAuthenticated && ifAdmin
    },
    {
      path: '/electricity-accounting/',
      name: 'ElectricityAccountingPage',
      component: ElectricityAccountingPage,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/electricity-accounting/view',
      name: 'ElectricityAccountingView',
      component: ElectricityAccountingView,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/electricity-control/',
      name: 'ElectricityControlPage',
      component: ElectricityControlPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/email-confirm/',
      name: 'EmailConfirmPage',
      component: EmailConfirmPage
    },
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login/',
      name: 'LoginPage',
      component: LoginPage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/logout/',
      name: 'LogoutPage',
      component: LogoutPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/my-shares/',
      name: 'MySharesPage',
      component: MySharesPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/operations-history/',
      name: 'OperationsHistoryPage',
      component: OperationsHistoryPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/owners/',
      name: 'OwnersPage',
      component: OwnersPage,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/owners/edit/',
      name: 'OwnersEditForm',
      component: OwnersEditForm,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/owners/view/',
      name: 'OwnersView',
      component: OwnersView,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/owners/fields/',
      name: 'OwnersFieldsPage',
      component: OwnersFieldsPage,
      beforeEnter: ifAuthenticated && ifAdmin
    },
    {
      path: '/owners/fields/edit',
      name: 'OwnersFieldsEditForm',
      component: OwnersFieldsEditForm,
      beforeEnter: ifAuthenticated && ifAdmin
    },
    {
      path: '/payment/',
      name: 'PaymentPage',
      component: PaymentPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/payment-transactions/',
      name: 'PaymentTransactionsPage',
      component: PaymentTransactionsPage,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/register/',
      name: 'RegisterPage',
      component: RegisterPage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/shares/',
      name: 'SharesPage',
      component: SharesPage,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/shares/edit/',
      name: 'SharesEditForm',
      component: SharesEditForm,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/shares/view/',
      name: 'SharesView',
      component: SharesView,
      beforeEnter: ifAuthenticated && (ifAdmin || ifAccounter)
    },
    {
      path: '/shares/fields/',
      name: 'SharesFieldsPage',
      component: SharesFieldsPage,
      beforeEnter: ifAuthenticated && ifAdmin
    },
    {
      path: '/shares/fields/edit',
      name: 'SharesFieldsEditForm',
      component: SharesFieldsEditForm,
      beforeEnter: ifAuthenticated && ifAdmin
    },
    {
      path: '/tariffs/',
      name: 'TariffsPage',
      component: TariffsPage,
      beforeEnter: ifAuthenticated && ifAdmin
    },
    {
      path: '/tariffs/edit',
      name: 'TariffsEditForm',
      component: TariffsEditForm,
      beforeEnter: ifAuthenticated && ifAdmin
    },
    {
      path: '/users/',
      name: 'UsersPage',
      component: UsersPage,
      beforeEnter: ifAuthenticated && ifAdmin
    }
  ]
})
