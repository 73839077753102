import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  fields: [],
  field: {},
  bottomsheetOpened: false
}

const getters = {
  ownerFieldsStatus: state => state.status,
  isOwnerFieldsBottomsheetOpen: state => !!state.bottomsheetOpened,
  ownerFields: state => state.fields,
  ownerField: state => state.field
}

const actions = {
  ['OWNER_FIELDS_GET']: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/owner-additional-fields', data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNER_FIELDS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNER_FIELDS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNER_FIELDS_ERROR')
          resolve(err)
        })
    })
  },
  ['OWNER_FIELDS_GET_FIELD']: ({ commit, dispatch }, field) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/owner-additional-fields/' + field.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNER_FIELDS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNER_FIELDS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNER_FIELDS_ERROR')
          resolve(err)
        })
    })
  },
  ['OWNER_FIELDS_SET']: ({ commit }, field) => {
    return new Promise((resolve) => {
      commit('OWNER_FIELDS_SET', field)
      resolve()
    })
  },
  ['OWNER_FIELDS_UPDATE']: ({ commit, dispatch }, field) => {
    return new Promise((resolve, reject) => {
      commit('OWNER_FIELDS_UPDATE')
      apiCall({ url: '/api/owner-additional-fields/edit', data: field, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNER_FIELDS_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNER_FIELDS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNER_FIELDS_ERROR', err)
          reject(err)
        })
    })
  },
  ['OWNER_FIELDS_REMOVE']: ({ commit, dispatch }, field) => {
    return new Promise((resolve, reject) => {
      commit('OWNER_FIELDS_REMOVE')
      apiCall({ url: '/api/owner-additional-fields/del', data: field, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('OWNER_FIELDS_REMOVE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('OWNER_FIELDS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('OWNER_FIELDS_ERROR', err)
          reject(err)
        })
    })
  },
  ['OWNER_FIELDS_BOTTOMSHEET_OPEN']: ({ commit }, fieldId) => {
    return new Promise((resolve) => {
      commit('OWNER_FIELDS_BOTTOMSHEET_OPEN', fieldId)
      resolve()
    })
  }
}

const mutations = {
  ['OWNER_FIELDS_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['OWNER_FIELDS_REMOVE']: (state) => {
    state.status = 'removing'
  },
  ['OWNER_FIELDS_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['OWNER_FIELDS_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['OWNER_FIELDS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.fields = resp.fields || []
    state.field = resp.field || {}
  },
  ['OWNER_FIELDS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['OWNER_FIELDS_BOTTOMSHEET_OPEN']: (state, fieldId) => {
    state.bottomsheetOpened = true
    state.field = {
      id: fieldId
    }
  },
  ['OWNER_FIELDS_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['OWNER_FIELDS_BOTTOMSHEET_CLEAN']: (state) => {
    state.field = {}
  },
  ['OWNER_FIELDS_CLEAN']: (state) => {
    state.field = {}
  },
  ['OWNER_FIELDS_SET']: (state, field) => {
    state.field = field
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
