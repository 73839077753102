<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Список участков</v-toolbar-title>
      <template v-if="$store.getters.isAdmin">
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="gotoSettings()" v-on="on">
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <span>Настроить поля</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="newShare()" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Новый участок</span>
        </v-tooltip>
      </template>
    </v-app-bar>
    <v-container fluid>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Найти"
        @input="updateProps()"
      ></v-text-field>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="listData.data"
        :options.sync="listOptions"
        :server-items-length="listData.total"
        :loading="dataLoading"
        :footer-props="listFooterProps"
        @update:options="updateProps()"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <a v-on="on" :href="'https://maps.yandex.ru/?text=СНТ Окское, ' + item.cluster + ' куст, ' + item.line + ' линия, ' + item.house" target="_blank" class="text-decoration-none"><text-highlight :queries="searchArray">{{ item.clh }}</text-highlight></a>
                </template>
                <span>Посмотреть на карте</span>
              </v-tooltip>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.cadastral_number }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.s }}</text-highlight>
            </td>
            <td>
              <p v-for="(counter, index) in item.counters" :key="'c'+index" class="mb-0">
                <a @click="viewCounter(counter.id)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <text-highlight :queries="searchArray">{{ counter.id }}</text-highlight>
                      </span>
                    </template>
                    <span>Используется с
                      <template v-if="counter.used_since === '0000-00-00'">незапамятных времён</template>
                      <template v-else>{{ $moment(new Date(counter.used_since)).format('DD.MM.YYYY') }}</template>
                      <br />
                      <template v-if="counter.used_till === null">по настоящее время</template>
                      <template v-else>по {{ $moment(new Date(counter.used_till)).format('DD.MM.YYYY') }}</template>
                    </span>
                  </v-tooltip>
                  <v-tooltip v-if="counter.used_till === null" right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" x-small class="ml-1" color="primary">mdi-check-decagram</v-icon>
                    </template>
                    <span>Действующий счётчик</span>
                  </v-tooltip>
                </a>
              </p>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ !!item.pm_prohibit ? 'да' : 'нет' }}</text-highlight>
            </td>
            <td>
              <p v-for="(owner, index) in item.owners" :key="'o'+index" class="mb-0">
                <a @click="viewOwner(owner.id)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <text-highlight :queries="searchArray">{{ owner.name }}</text-highlight>
                      </span>
                    </template>
                    <span>Владелец с
                      <template v-if="owner.owned_since === '0000-00-00'">незапамятных времён</template>
                      <template v-else>{{ $moment(new Date(owner.owned_since)).format('DD.MM.YYYY') }}</template>
                      <br />
                      <template v-if="owner.owned_till === null">по настоящее время</template>
                      <template v-else>по {{ $moment(new Date(owner.owned_till)).format('DD.MM.YYYY') }}</template>
                    </span>
                  </v-tooltip>
                  <v-tooltip v-if="owner.owned_till === null" right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" x-small class="ml-1" color="primary">mdi-check-decagram</v-icon>
                    </template>
                    <span>Последний владелец</span>
                  </v-tooltip>
                </a>
              </p>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.comment }}</text-highlight>
            </td>
            <td :class="getClass(item.m_debt)">
              <text-highlight :queries="searchArray">{{ item.m_debt }}</text-highlight>
            </td>
            <td :class="getClass(item.t_debt)">
              <text-highlight :queries="searchArray">{{ item.t_debt }}</text-highlight>
            </td>
            <td :class="getClass(item.nt_debt)">
              <text-highlight :queries="searchArray">{{ item.nt_debt }}</text-highlight>
            </td>
            <td :class="getClass(item.e_debt)">
              <text-highlight :queries="searchArray">{{ item.e_debt }}</text-highlight>
            </td>
            <td v-for="(field, index) in fields" :key="index">
                <template v-if="field.type === 'checkbox' && item[field.id] !== null">
                  <text-highlight :queries="searchArray">{{ item[field.id] === 'true' ? 'да' : 'нет' }}</text-highlight>
                </template>
                <template v-else>
                  <text-highlight :queries="searchArray">{{ item[field.id] }}</text-highlight>
                </template>
            </td>
            <td>
              <v-btn icon @click.stop="showBottomsheet(item.id)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="bottomSheetVisible">
        <v-list>
          <v-list-item @click="viewObj()">
            <v-list-item-avatar>
              <v-icon>mdi-eye-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Посмотреть</v-list-item-title>
          </v-list-item>
          <v-list-item @click="editObj()">
            <v-list-item-avatar>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Изменить</v-list-item-title>
          </v-list-item>
          <v-list-item @click="removeObjConfirm()">
            <v-list-item-avatar>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Удалить</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-dialog v-model="dialogVisible" max-width="300">
        <v-card>
          <v-card-text class="text-center py-6">Удалить участок?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="removeObj()">Да</v-btn>
            <v-btn text @click="removeClean()">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      listHeaders: [
        { text: 'К-Л-Д', value: 'clh' },
        { text: 'КН', value: 'cadastral_number' },
        { text: 'S', value: 's' },
        { text: 'Счётчики', value: 'counters' },
        { text: 'Запрет УЭ', value: 'pm_prohibit' },
        { text: 'Владельцы', value: 'owners' },
        { text: 'Комментарий', value: 'comment'},
        { text: 'Долг ЧЛ', value: 'm_debt' },
        { text: 'Долг Ц', value: 't_debt' },
        { text: 'Долг НЦ', value: 'nt_debt' },
        { text: 'Долг Э', value: 'e_debt' },
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['clh'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: '',
      fields: []
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    },
    bottomSheetVisible: {
      get: function () {
        return this.$store.getters.isSharesBottomsheetOpen
      },
      set: function () {
        this.$store.commit('SHARES_BOTTOMSHEET_CLOSE')
        this.$store.commit('SHARES_BOTTOMSHEET_CLEAN')
      }
    },
    dialogVisible: {
      get: function () {
        return this.$store.getters.isDialogOpen
      },
      set: function () {
        this.$store.commit('DIALOG_CLOSE')
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      let options = {
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      this.$store.dispatch('SHARES_GET', options).then(() => {
        this.dataLoading = false
        if (this.$store.getters.sharesStatus === 'success') {
          this.listData = this.$store.getters.shares
          this.listData.data.forEach(function (item) {
            try {
              if (item.owners.length > 1) {
                item.owners.sort(function (a, b) {
                  let da = new Date(a.owned_till)
                  let db = new Date(b.owned_till)
                  return (da.getTime() > db.getTime()) ? 1 : ((db.getTime() > da.getTime()) ? -1 : 0)
                })
              }
            } catch (e) {
              item.owners = []
            }
            try {
              if (item.counters.length > 1) {
                item.counters.sort(function (a, b) {
                  let da = new Date(a.used_till)
                  let db = new Date(b.used_till)
                  return (da.getTime() > db.getTime()) ? 1 : ((db.getTime() > da.getTime()) ? -1 : 0)
                })
              }
            } catch (e) {
              item.counters = []
            }
          }, this)
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.loadData()
    },
    gotoSettings: function () {
      this.$router.push('/shares/fields')
    },
    showBottomsheet: function (objId) {
      this.$store.dispatch('SHARES_BOTTOMSHEET_OPEN', objId)
    },
    viewObj: function () {
      this.$store.commit('SHARES_BOTTOMSHEET_CLOSE')
      this.$router.push('/shares/view/')
    },
    editObj: function () {
      this.$store.commit('SHARES_BOTTOMSHEET_CLOSE')
      this.$router.push('/shares/edit/')
    },
    removeClean: function () {
      this.dialogVisible = false
      this.$store.commit('SHARES_BOTTOMSHEET_CLEAN')
    },
    removeObj: function () {
      this.$store.commit('DIALOG_CLOSE')
      this.$store.dispatch('SHARES_REMOVE', { id: this.$store.getters.share.id }).then(() => {
        if (this.$store.getters.sharesStatus === 'success') {
          this.loadData()
          this.$store.commit('SNACKBAR_SET', 'Удалено успешно')
        } else {
          this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка')
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка. ' + err)
      }).then(() => {
        this.$store.commit('SHARES_BOTTOMSHEET_CLOSE')
        this.$store.commit('SHARES_BOTTOMSHEET_CLEAN')
      })
    },
    removeObjConfirm: function () {
      this.$store.commit('SHARES_BOTTOMSHEET_CLOSE')
      this.$store.dispatch('DIALOG_OPEN', this.$store.getters.share)
    },
    newShare: function () {
      this.$store.dispatch('SHARES_SET', { id: null, extra: {}, owners: [] }).then(() => {
        this.$router.push('/shares/edit/')
      })
    },
    viewOwner: function (ownerId) {
      this.$store.dispatch('OWNERS_SET', { id: ownerId, extra: {}, relatives: [] }).then(() => {
        this.$router.push('/owners/view/')
      })
    },
    viewCounter: function (counterId) {
      this.$store.dispatch('COUNTERS_SET', { id: counterId }).then(() => {
        this.$router.push('/counters/view/')
      })
    },
    getClass: function (value) {
      if (value > 0) return 'd-green'
      else if (value < 0) return 'd-red'
      else return ''
    }
  },
  mounted: function () {
    this.$store.dispatch('SHARE_FIELDS_GET').then(() => {
      if (this.$store.getters.shareFieldsStatus === 'success') {
        this.$store.getters.shareFields.forEach(function (field) {
          if (field.show_in_table) {
            this.fields.push({
              id: 'f' + field.id,
              type: field.type
            })
            this.listHeaders.push({
              text: field.description,
              value: 'f' + field.id
            })
          }
        }, this)
        this.listHeaders.push({
          text: '',
          value: 'actions',
          sortable: false,
          filterable: false
        })
        this.$root.setTitle('Список участков')
      } else {
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
      }
    }, (err) => {
      err = ''
      this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
    })
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>
</style>
