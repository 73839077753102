import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import auth from './modules/auth'
import charges from './modules/charges'
import config from './modules/config'
import dialog from './modules/dialog'
import electricValues from './modules/electric-values'
import menu from './modules/menu'
import ownerFields from './modules/owner-fields'
import owners from './modules/owners'
import shareFields from './modules/share-fields'
import shares from './modules/shares'
import snackbar from './modules/snackbar'
import tariffs from './modules/tariffs'
import uins from './modules/uins'
import users from './modules/users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    auth,
    charges,
    config,
    dialog,
    electricValues,
    menu,
    ownerFields,
    owners,
    shareFields,
    shares,
    snackbar,
    tariffs,
    uins,
    users
  }
})
