<template>
  <v-navigation-drawer v-model="menu" app width="400" temporary>
    <p class="text-center">
      <img src="../assets/logo.svg" alt="СНТ Окское" style="max-width: 200px;" class="mt-3 mb-1" />
      <span class="d-block mb-3 text-h4">СНТ Окское</span>
    </p>
    <v-btn-toggle v-if="hasRoles" v-model="userRole" tile mandatory color="primary" class="mx-3 d-flex w-100" group>
      <v-btn v-if="hasRoles" value="owner">Садовод</v-btn>
      <v-btn v-if="$store.getters.isAdmin" value="admin">Администратор</v-btn>
      <v-btn v-if="$store.getters.isAccounter" value="admin">Бухгалтер</v-btn>
    </v-btn-toggle>
    <v-list>
      <v-list-item-group :mandatory="mandatoryIndex">
        <v-list-item link :to="page.link" v-for="page in pages" :key="page.link">
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ page.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      mandatoryIndex: false,
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    userRole: {
      get: function () {
        return this.$store.getters.userRole
      },
      set: function (value) {
        this.$store.commit('ACCOUNT_SET_ROLE', value)
      }
    },
    pages: function () {
      let pages = [
        {
          label: 'Вход',
          link: '/login/',
          icon: 'mdi-login',
          auth: false
        },
        {
          label: 'Регистрация',
          link: '/register/',
          icon: 'mdi-account-plus-outline',
          auth: false
        },
        /*
        {
          label: 'Главная',
          link: '/',
          icon: 'mdi-monitor-dashboard',
          auth: true
        },
        */
        {
          label: 'Электроэнергия',
          link: '/electricity-control/',
          icon: 'mdi-flash',
          auth: true,
          roles: ['owner']
        },
        {
          label: 'Оплата',
          link: '/payment/',
          icon: 'mdi-currency-rub',
          auth: true,
          roles: ['owner']
        },
        {
          label: 'История операций',
          link: '/operations-history/',
          icon: 'mdi-history',
          auth: true,
          roles: ['owner']
        },
        {
          label: 'Мои участки',
          link: '/my-shares/',
          icon: 'mdi-layers-outline',
          auth: true,
          roles: ['owner']
        },
        {
          label: 'Садоводы',
          link: '/owners/',
          icon: 'mdi-account-supervisor-outline',
          auth: true,
          roles: ['admin', 'accounter']
        },
        {
          label: 'Участки',
          link: '/shares/',
          icon: 'mdi-view-dashboard-outline',
          auth: true,
          roles: ['admin', 'accounter']
        },
        {
          label: 'Учёт электроэнергии',
          link: '/electricity-accounting/',
          icon: 'mdi-counter',
          auth: true,
          roles: ['admin', 'accounter']
        },
        {
          label: 'Платёжные операции',
          link: '/payment-transactions/',
          icon: 'mdi-cash-multiple',
          auth: true,
          roles: ['admin', 'accounter']
        },
        {
          label: 'Начисления',
          link: '/charges/',
          icon: 'mdi-cash-plus',
          auth: true,
          roles: ['admin', 'accounter']
        },
        {
          label: 'Тарифы',
          link: '/tariffs/',
          icon: 'mdi-cash-register',
          auth: true,
          roles: ['admin']
        },
        {
          label: 'Константы',
          link: '/config/',
          icon: 'mdi-cogs',
          auth: true,
          roles: ['admin']
        },
        {
          label: 'Пользователи сайта',
          link: '/users/',
          icon: 'mdi-account-box-multiple-outline',
          auth: true,
          roles: ['admin']
        },
        {
          label: 'Аккаунт',
          link: '/account/',
          icon: 'mdi-account-box-outline',
          auth: true
        },
        {
          label: 'Выход',
          link: '/logout/',
          icon: 'mdi-logout',
          auth: true
        }
      ]
      return pages.filter(page => (page.auth === this.$store.getters.isAuthenticated && (page.roles === undefined || page.roles.indexOf(this.$store.getters.userRole) !== -1)))
    },
    hasRoles: function () {
      try {
        return this.$store.getters.user.roles.length && this.$store.getters.isAuthenticated
      } catch (e) {
        return false
      }
    }
  },
  watch: {
    $route: function (to) {
      this.getPage(to)
    }
  },
  methods: {
    getPage: function (route) {
      this.mandatoryIndex = false
      this.pages.forEach(function (page) {
        if (page.link === route.path) {
          this.mandatoryIndex = true
        }
      }, this)
    }
  },
  mounted: function () {
    this.getPage(this.$router.currentRoute)
  },
  created: function () {
    if (this.$store.getters.user.id === undefined && this.$router.currentRoute.name !== 'LoginPage') this.$store.dispatch('ACCOUNT_GET')
  }
}
</script>

<style>
.v-subheader {
  font-size: 1.25rem!important;
  text-transform: uppercase!important;
}
.d-green {
  background-color: rgba(76, 175, 80, .1);
}
.d-red {
  background-color: rgba(244, 67, 54, .1);
}
</style>
