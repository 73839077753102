import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  uins: [],
  uin: {
    extra: {}
  },
  bottomsheetOpened: false
}

const getters = {
  uinsStatus: state => state.status,
  isUinsBottomsheetOpen: state => !!state.bottomsheetOpened,
  uins: state => state.uins,
  uin: state => state.uin
}

const actions = {
  ['UINS_GET_UIN']: ({ commit, dispatch }, uin) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/uin/' + uin.uin, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('UINS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('UINS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('UINS_ERROR')
          resolve(err)
        })
    })
  },
  ['UINS_GET_BY_SHARE']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/shares/' + options.id + '/charges', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('UINS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('UINS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('UINS_ERROR')
          resolve(err)
        })
    })
  },
  ['UINS_GET_BY_OWNER']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/owners/' + options.id + '/charges', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('UINS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('UINS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('UINS_ERROR')
          resolve(err)
        })
    })
  },
  ['UINS_GET_BY_CHARGE']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/charges/' + options.id + '/uins', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('UINS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('UINS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('UINS_ERROR')
          resolve(err)
        })
    })
  },
  ['UINS_SET']: ({ commit }, uin) => {
    return new Promise((resolve) => {
      commit('UINS_SET', uin)
      resolve()
    })
  },
  ['UINS_BOTTOMSHEET_OPEN']: ({ commit }, uin) => {
    return new Promise((resolve) => {
      commit('UINS_BOTTOMSHEET_OPEN', uin)
      resolve()
    })
  }
}

const mutations = {
  ['UINS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.uins = resp.uins || []
    state.uin = resp.uin || {
      extra: {}
    }
  },
  ['UINS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['UINS_SET']: (state, uin) => {
    state.uin = uin
    state.uin.extra = uin.extra || {}
  },
  ['UINS_BOTTOMSHEET_OPEN']: (state, uin) => {
    state.bottomsheetOpened = true
    state.uin = uin
  },
  ['UINS_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['UINS_BOTTOMSHEET_CLEAN']: (state) => {
    state.uin = {
      extra: {}
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
