<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Начисления</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="newCharge()" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Новое начисление</span>
      </v-tooltip>
    </v-app-bar>
    <v-container fluid>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Найти"
        @input="updateProps()"
      ></v-text-field>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="listData.data"
        :options.sync="listOptions"
        :server-items-length="listData.total"
        :loading="dataLoading"
        :footer-props="listFooterProps"
        @update:options="updateProps()"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <text-highlight :queries="searchArray">{{ item.id }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(new Date(item.date_create)).format('DD.MM.YYYY HH:mm:ss') }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(new Date(item.date_till)).format('DD.MM.YYYY') }}</text-highlight>
            </td>
            <td>
              <template v-if="item.amount">
                <text-highlight :queries="searchArray">{{ item.amount }}</text-highlight>
                <template> ₽</template>
                <template v-if="item.type === 'членские'"> за м²</template>
              </template>
              <template v-else>по участкам</template>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.type }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.purpose }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.status }}</text-highlight>
              <v-btn v-if="item.status === 'создано'" :disabled="$store.getters.charge.id === item.id" small outlined class="ml-2" @click="chargeConfirm(item)">Начислить</v-btn>
            </td>
            <td>
              <v-btn icon @click.stop="showBottomsheet(item)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="bottomSheetVisible">
        <v-list>
          <v-list-item @click="viewObj()">
            <v-list-item-avatar>
              <v-icon>mdi-eye-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Посмотреть</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.charge.status === 'создано'" @click="editObj()">
            <v-list-item-avatar>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Изменить</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.charge.status === 'создано'" @click="removeObjConfirm()">
            <v-list-item-avatar>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Удалить</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-dialog v-model="dialogVisible" max-width="300">
        <v-card>
          <v-card-text v-if="dialogType === 'remove'" class="text-center py-6">Удалить начисление?</v-card-text>
          <v-card-text v-else class="text-center py-6">Начислить?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="dialogType === 'remove'" text @click="removeObj()">Да</v-btn>
            <v-btn v-else text @click="charge()">Да</v-btn>
            <v-btn v-if="dialogType === 'remove'" text @click="removeClean()">Нет</v-btn>
            <v-btn v-else text @click="chargeClean()">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      listHeaders: [
        { text: 'Код', value: 'id' },
        { text: 'Дата создания', value: 'date_create' },
        { text: 'Срок оплаты', value: 'date_till' },
        { text: 'Сумма', value: 'amount' },
        { text: 'Тип', value: 'type' },
        { text: 'Назначение платежа', value: 'purpose' },
        { text: 'Статус', value: 'status' },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: '',
      dialogType: 'remove'
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    },
    bottomSheetVisible: {
      get: function () {
        return this.$store.getters.isChargesBottomsheetOpen
      },
      set: function () {
        this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
        this.$store.commit('CHARGES_BOTTOMSHEET_CLEAN')
      }
    },
    dialogVisible: {
      get: function () {
        return this.$store.getters.isDialogOpen
      },
      set: function () {
        this.$store.commit('DIALOG_CLOSE')
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      let options = {
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      this.$store.dispatch('CHARGES_GET', options).then(() => {
        this.dataLoading = false
        if (this.$store.getters.chargesStatus === 'success') {
          this.listData = this.$store.getters.charges
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.loadData()
    },
    showBottomsheet: function (obj) {
      this.$store.dispatch('CHARGES_BOTTOMSHEET_OPEN', obj)
    },
    viewObj: function () {
      this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
      this.$router.push('/charges/view/')
    },
    editObj: function () {
      this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
      this.$router.push('/charges/edit/')
    },
    removeClean: function () {
      this.dialogVisible = false
      this.$store.commit('CHARGES_BOTTOMSHEET_CLEAN')
    },
    removeObj: function () {
      this.$store.commit('DIALOG_CLOSE')
      this.$store.dispatch('CHARGES_REMOVE', { id: this.$store.getters.charge.id }).then(() => {
        if (this.$store.getters.chargesStatus === 'success') {
          this.loadData()
          this.$store.commit('SNACKBAR_SET', 'Удалено успешно')
        } else {
          this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка')
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка. ' + err)
      }).then(() => {
        this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
        this.$store.commit('CHARGES_BOTTOMSHEET_CLEAN')
      })
    },
    removeObjConfirm: function () {
      this.dialogType = 'remove'
      this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
      this.$store.dispatch('DIALOG_OPEN', this.$store.getters.charge)
    },
    chargeConfirm: function (item) {
      this.dialogType = 'charge'
      this.$store.dispatch('CHARGES_SET', item).then(() => {
        this.$store.dispatch('DIALOG_OPEN', this.$store.getters.charge)
      })
    },
    chargeClean: function () {
      this.dialogVisible = false
      this.$store.commit('CHARGES_BOTTOMSHEET_CLEAN')
    },
    charge: function () {
      this.$store.commit('DIALOG_CLOSE')
      this.$store.dispatch('CHARGES_CHARGE', { id: this.$store.getters.charge.id }).then(() => {
        if (this.$store.getters.chargesStatus === 'success') {
          this.loadData()
          this.$store.commit('SNACKBAR_SET', 'Начислено успешно')
        } else {
          this.$store.commit('SNACKBAR_SET', 'При начислении произошла ошибка')
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При начислении произошла ошибка. ' + err)
      }).then(() => {
        this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
        this.$store.commit('CHARGES_BOTTOMSHEET_CLEAN')
      })
    },
    newCharge: function () {
      this.$store.dispatch('CHARGES_SET', { id: null, type: 'членские', extra: {} }).then(() => {
        this.$router.push('/charges/edit/')
      })
    }
  },
  mounted: function () {
    this.$root.setTitle('Начисления')
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>

</style>
