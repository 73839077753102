<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Просмотр начисления</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip v-if="obj.status === 'создано'" left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click.stop="editCharge()" v-on="on">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Изменить начисление</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-list>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Начисление № {{ obj.id }} — {{ obj.type }}
              </span>
            </v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Статус</v-list-item-title>
                <v-list-item-subtitle>
                  {{ obj.status }}
                  <v-btn v-if="obj.status === 'создано'" :disabled="buttonDisabled" outlined class="ml-2" @click="charge()">Начислить</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Создано</v-list-item-title>
                <v-list-item-subtitle>{{ $moment(new Date(obj.date_create)).format('DD.MM.YYYY HH:mm:ss') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Оплатить до</v-list-item-title>
                <v-list-item-subtitle>{{ $moment(new Date(obj.date_till)).format('DD.MM.YYYY') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Назначение платежа</v-list-item-title>
                <v-list-item-subtitle>{{ obj.purpose }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Тип начисления</v-list-item-title>
                <v-list-item-subtitle>{{ obj.type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Сумма</v-list-item-title>
                <v-list-item-subtitle>
                  <template v-if="obj.amount">
                    {{ obj.amount }}
                    <template> ₽</template>
                    <template v-if="obj.type === 'членские'"> за м²</template>
                  </template>
                  <template v-else>по участкам</template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>
              <span class="d-inline-block text-truncate">
                  Начисления
              </span>
            </v-subheader>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Найти"
              @input="updateProps()"
            ></v-text-field>
            <v-data-table
              fixed-header
              :headers="listHeaders"
              :items="listData.data"
              :options.sync="listOptions"
              :server-items-length="listData.total"
              :loading="dataLoading"
              :footer-props="listFooterProps"
              @update:options="updateProps()"
              >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <text-highlight :queries="searchArray">{{ item.uin }}</text-highlight>
                  </td>
                  <td>
                    <text-highlight :queries="searchArray">{{ item.clh }}</text-highlight>
                  </td>
                  <td>
                    <text-highlight :queries="searchArray">{{ item.full_name }}</text-highlight>
                  </td>
                  <td>
                    <text-highlight :queries="searchArray">{{ item.amount }}</text-highlight>
                  </td>
                  <td>
                    <text-highlight :queries="searchArray">{{ item.status }}</text-highlight>
                  </td>
                  <td><v-btn v-if="item.status === 'не оплачено'" small outlined @click="makeReceipt(item)">Квитанция</v-btn></td>
                </tr>
              </template>
            </v-data-table>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      obj: {
        extra: {
          shares: []
        }
      },
      buttonDisabled: false,
      listHeaders: [
        { text: 'УИН', value: 'uin' },
        { text: 'Участок', value: 'clh' },
        { text: 'ФИО', value: 'full_name' },
        { text: 'Сумма', value: 'amount' },
        { text: 'Статус', value: 'status' },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['clh'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: '',
    }
  },
  computed: {
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
      this.$store.commit('CHARGES_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    editCharge: function () {
      this.$router.push('/charges/edit/')
    },
    getCharge: function () {
      return new Promise ((resolve, reject) => {
        this.$store.dispatch('CHARGES_GET_CHARGE', this.$store.getters.charge).then(() => {
          if (this.$store.getters.chargesStatus === 'success') {
            this.obj = this.$store.getters.charge
            this.obj.extra = this.obj.extra || {}
            this.obj.extra.shares = this.obj.extra.shares || []
            resolve()
          } else {
            this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
            reject()
          }
        }, (err) => {
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
          reject()
        })
      })
    },
    charge: function () {
      this.buttonDisabled = true
      this.$store.dispatch('CHARGES_CHARGE', { id: this.obj.id }).then(() => {
        if (this.$store.getters.chargesStatus === 'success') {
          this.getCharge()
          this.$store.commit('SNACKBAR_SET', 'Начислено успешно')
        } else {
          this.buttonDisabled = false
          this.$store.commit('SNACKBAR_SET', 'При начислении произошла ошибка')
        }
      }, (err) => {
        this.buttonDisabled = false
        this.$store.commit('SNACKBAR_SET', 'При начислении произошла ошибка. ' + err)
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.getUINs()
    },
    getUINs: function () {
      this.dataLoading = true
      let options = {
        id: this.obj.id,
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      if (options.id) {
        this.$store.dispatch('UINS_GET_BY_CHARGE', options).then(() => {
          this.dataLoading = false
          if (this.$store.getters.uinsStatus === 'success') {
            this.listData = this.$store.getters.uins
          } else {
            this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки начислений')
          }
        }, (err) => {
          this.dataLoading = false
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки начислений' + err)
        })
      } else {
        this.dataLoading = false
      }
    },
    makeReceipt: function (uin) {
      this.$store.dispatch('UINS_SET', uin).then(() => {
        this.$router.push('/charges/receipt/')
      })
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.charge
      if (this.obj.id === undefined) this.backButton()
      else if (this.obj.id !== null) {
        this.getCharge().then(() => {
          this.getUINs()
        })
        this.$root.setTitle('Просмотр начисления')
      }
    } catch (e) {
      this.backButton()
    }
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>
</style>
