const state = { message: '' }

const getters = {
  snackbarMessage: state => state.message
}

const mutations = {
  ['SNACKBAR_SET'] (state, message) {
    state.message = message
  }
}

export default {
  state,
  getters,
  mutations
}
