import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  shares: [],
  share: {
    owners: [],
    counters: [],
    debt: {},
    extra: {}
  },
  bottomsheetOpened: false
}

const getters = {
  sharesStatus: state => state.status,
  isSharesBottomsheetOpen: state => !!state.bottomsheetOpened,
  shares: state => state.shares,
  share: state => state.share
}

const actions = {
  ['SHARES_GET']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/shares', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('SHARES_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('SHARES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('SHARES_ERROR')
          resolve(err)
        })
    })
  },
  ['SHARES_SEARCH_GET']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/shares/search', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('SHARES_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('SHARES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('SHARES_ERROR')
          resolve(err)
        })
    })
  },
  ['SHARES_UPDATE']: ({ commit, dispatch }, share) => {
    return new Promise((resolve, reject) => {
      commit('SHARES_UPDATE')
      apiCall({ url: '/api/shares/edit', data: share, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('SHARES_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('SHARES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('SHARES_ERROR', err)
          reject(err)
        })
    })
  },
  ['SHARES_REMOVE']: ({ commit, dispatch }, share) => {
    return new Promise((resolve, reject) => {
      commit('SHARES_REMOVE')
      apiCall({ url: '/api/shares/del', data: share, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('SHARES_REMOVE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('SHARES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('SHARES_ERROR', err)
          reject(err)
        })
    })
  },
  ['SHARES_GET_SHARE']: ({ commit, dispatch }, share) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/shares/' + share.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('SHARES_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('SHARES_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('SHARES_ERROR')
          resolve(err)
        })
    })
  },
  ['SHARES_SET']: ({ commit }, share) => {
    return new Promise((resolve) => {
      commit('SHARES_SET', share)
      resolve()
    })
  },
  ['SHARES_BOTTOMSHEET_OPEN']: ({ commit }, shareId) => {
    return new Promise((resolve) => {
      commit('SHARES_BOTTOMSHEET_OPEN', shareId)
      resolve()
    })
  }
}

const mutations = {
  ['SHARES_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['SHARES_REMOVE']: (state) => {
    state.status = 'removing'
  },
  ['SHARES_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['SHARES_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['SHARES_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.shares = resp.shares || []
    state.share = resp.share || {}

  },
  ['SHARES_ERROR']: (state) => {
    state.status = 'error'
  },
  ['SHARES_SET']: (state, share) => {
    state.share = share
    state.share.owners = share.owners || []
    state.share.counters = share.counters || []
  },
  ['SHARES_BOTTOMSHEET_OPEN']: (state, shareId) => {
    state.bottomsheetOpened = true
    state.share = {
      id: shareId,
      owners: [],
      counters: [],
      debt: {},
      extra: {}
    }
  },
  ['SHARES_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['SHARES_BOTTOMSHEET_CLEAN']: (state) => {
    state.share = {
      owners: [],
      counters: [],
      debt: {},
      extra: {}
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
