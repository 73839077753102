<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Вход</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click.stop="doLogin()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Войти</span>
      </v-tooltip>
    </v-app-bar>
    <v-container @keyup.enter="doLogin()">
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11">
          <v-form ref="loginForm" v-model="formValid">
            <v-text-field label="Войти"
              v-model.trim="login.email"
              type="email"
              autofocus
              autocomplete="email"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле', v => /.+@.+\..+/.test(v) || 'Пожалуйста, укажите правильный email' ]">
            </v-text-field>
            <v-text-field label="Пароль"
              v-model="login.password"
              :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              autocomplete="current-password"
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-btn @click.stop="doLogin()" :disabled="!formValid" color="primary" class="mt-3">Войти</v-btn>
            <p class="mt-8">
              <a @click.stop="forgotPassword()">Забыли пароль?</a>
              &nbsp;&bull;&nbsp;
              <a @click.stop="$router.push('/register/')">Регистрация</a>
            </p>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      login: {},
      showPassword: false,
      formValid: true
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    }
  },
  methods: {
    doLogin: function () {
      if (this.$refs.loginForm.validate()) {
        let { email, password } = this.login
        this.$store.dispatch('AUTH_REQUEST', { email, password }).then(() => {
          this.$router.push('/')
        }, (err) => {
          switch (err) {
            case 0:
              this.$store.commit('SNACKBAR_SET', 'Пользователь не найден или учётная запись не активирована')
              break
            case 1:
              this.$store.commit('SNACKBAR_SET', 'Неверная пара логин/пароль')
              break
            default:
              this.$store.commit('SNACKBAR_SET', 'Ошибка аутентификации: ' + err)
              break
          }
        })
      }
    },
    forgotPassword: function () {
      this.$router.push('/password-reset/')
    }
  },
  mounted: function () {
    this.$refs.loginForm.resetValidation()
    this.$root.setTitle('Вход')
  }
}
</script>

<style scoped>
</style>
