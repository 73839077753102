<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Список тарифов</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="newTariff()" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Новый тариф</span>
      </v-tooltip>
    </v-app-bar>
    <v-container fluid>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Найти"
        @input="updateProps()"
      ></v-text-field>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="listData.data"
        :options.sync="listOptions"
        :server-items-length="listData.total"
        :loading="dataLoading"
        :footer-props="listFooterProps"
        @update:options="updateProps()"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <text-highlight :queries="searchArray">{{ item.id }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(new Date(item.date_since)).format('DD.MM.YYYY') }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(new Date(item.date_till)).format('DD.MM.YYYY') }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.price_norm }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.price_over }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.status }}</text-highlight>
            </td>
            <td>
              <v-btn v-if="item.status == 'не вступил в силу'" icon @click.stop="showBottomsheet(item)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="bottomSheetVisible">
        <v-list>
          <v-list-item @click="editObj()">
            <v-list-item-avatar>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Изменить</v-list-item-title>
          </v-list-item>
          <v-list-item @click="removeObjConfirm()">
            <v-list-item-avatar>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Удалить</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-dialog v-model="dialogVisible" max-width="300">
        <v-card>
          <v-card-text class="text-center py-6">Удалить тариф?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="removeObj()">Да</v-btn>
            <v-btn text @click="removeClean()">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      listHeaders: [
        { text: 'Код', value: 'id' },
        { text: 'Первый день действия тарифа', value: 'date_since' },
        { text: 'Последний день действия тарифа	', value: 'date_till' },
        { text: 'Стоимость кВт в пределах нормы', value: 'price_norm' },
        { text: 'Стоимость кВт сверх нормы', value: 'price_over' },
        { text: 'Статус', value: 'status' },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: ''
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    },
    bottomSheetVisible: {
      get: function () {
        return this.$store.getters.isTariffsBottomsheetOpen
      },
      set: function () {
        this.$store.commit('TARIFF_BOTTOMSHEET_CLOSE')
        this.$store.commit('TARIFF_BOTTOMSHEET_CLEAN')
      }
    },
    dialogVisible: {
      get: function () {
        return this.$store.getters.isDialogOpen
      },
      set: function () {
        this.$store.commit('DIALOG_CLOSE')
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      let options = {
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      this.$store.dispatch('TARIFF_GET', options).then(() => {
        this.dataLoading = false
        if (this.$store.getters.tariffsStatus === 'success') {
          this.listData = this.$store.getters.tariffs
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.loadData()
    },
    showBottomsheet: function (obj) {
      this.$store.dispatch('TARIFF_BOTTOMSHEET_OPEN', obj)
    },
    editObj: function () {
      this.$store.commit('TARIFF_BOTTOMSHEET_CLOSE')
      this.$router.push('/tariffs/edit/')
    },
    removeClean: function () {
      this.dialogVisible = false
      this.$store.commit('TARIFF_BOTTOMSHEET_CLEAN')
    },
    removeObj: function () {
      this.$store.commit('DIALOG_CLOSE')
      this.$store.dispatch('TARIFF_REMOVE', { id: this.$store.getters.tariff.id }).then(() => {
        if (this.$store.getters.tariffsStatus === 'success') {
          this.loadData()
          this.$store.commit('SNACKBAR_SET', 'Удалено успешно')
        } else {
          this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка')
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка. ' + err)
      }).then(() => {
        this.$store.commit('TARIFF_BOTTOMSHEET_CLOSE')
        this.$store.commit('TARIFF_BOTTOMSHEET_CLEAN')
      })
    },
    removeObjConfirm: function () {
      this.dialogType = 'remove'
      this.$store.commit('TARIFF_BOTTOMSHEET_CLOSE')
      this.$store.dispatch('DIALOG_OPEN', this.$store.getters.tariff)
    },
    newTariff: function () {
      this.$store.dispatch('TARIFF_SET', { id: null, price_norm: null, price_over: null }).then(() => {
        this.$router.push('/tariffs/edit/')
      })
    }
  },
  mounted: function () {
    this.$root.setTitle('Список тарифов')
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>

</style>
