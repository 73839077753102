import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  tariffs: [],
  tariff: {},
  bottomsheetOpened: false
}

const getters = {
  tariffsStatus: state => state.status,
  isTariffsBottomsheetOpen: state => !!state.bottomsheetOpened,
  tariffs: state => state.tariffs,
  tariff: state => state.tariff
}

const actions = {
  ['TARIFF_GET']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/tariffs', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('TARIFF_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('TARIFF_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('TARIFF_ERROR')
          resolve(err)
        })
    })
  },
  ['TARIFF_UPDATE']: ({ commit, dispatch }, tariff) => {
    return new Promise((resolve, reject) => {
      commit('TARIFF_UPDATE')
      apiCall({ url: '/api/tariffs/edit', data: tariff, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('TARIFF_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('TARIFF_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('TARIFF_ERROR', err)
          reject(err)
        })
    })
  },
  ['TARIFF_REMOVE']: ({ commit, dispatch }, tariff) => {
    return new Promise((resolve, reject) => {
      commit('TARIFF_REMOVE')
      apiCall({ url: '/api/tariffs/del', data: tariff, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('TARIFF_REMOVE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('TARIFF_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('TARIFF_ERROR', err)
          reject(err)
        })
    })
  },
  ['TARIFF_GET_TARIFF']: ({ commit, dispatch }, tariff) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/tariffs/' + tariff.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('TARIFF_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('TARIFF_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('TARIFF_ERROR')
          resolve(err)
        })
    })
  },
  ['TARIFF_SET']: ({ commit }, tariff) => {
    return new Promise((resolve) => {
      commit('TARIFF_SET', tariff)
      resolve()
    })
  },
  ['TARIFF_BOTTOMSHEET_OPEN']: ({ commit }, tariff) => {
    return new Promise((resolve) => {
      commit('TARIFF_BOTTOMSHEET_OPEN', tariff)
      resolve()
    })
  }
}

const mutations = {
  ['TARIFF_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['TARIFF_REMOVE']: (state) => {
    state.status = 'removing'
  },
  ['TARIFF_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['TARIFF_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['TARIFF_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.tariffs = resp.tariffs || []
    state.tariff = resp.tariff || {}

  },
  ['TARIFF_ERROR']: (state) => {
    state.status = 'error'
  },
  ['TARIFF_SET']: (state, tariff) => {
    state.tariff = tariff
  },
  ['TARIFF_BOTTOMSHEET_OPEN']: (state, tariff) => {
    state.bottomsheetOpened = true
    state.tariff = {
      id: tariff.id,
      status: tariff.status
    }
  },
  ['TARIFF_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['TARIFF_BOTTOMSHEET_CLEAN']: (state) => {
    state.tariff = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
