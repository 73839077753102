<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Оплата</v-toolbar-title>
    </v-app-bar>
    <v-container>
      Оплата
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    }
  },
  methods: {
  },
  mounted: function () {
    this.$root.setTitle('Оплата')
  }
}
</script>

<style scoped>

</style>
