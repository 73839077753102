<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title><template v-if="obj.id === null">Создание</template><template v-else>Изменение</template> начисления</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="editCharge()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Сохранить</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="7" lg="8" md="9" sm="11">
          <v-form lazy-validation ref="chargeForm" v-model="formValid">
            <v-menu v-model="dateTillMenu" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable
                  :value="dateTill"
                  label="Оплатить до"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="obj.date_till = null"
                  :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="obj.date_till"
                @change="dateTillMenu = false"
                :min="new Date().toISOString()"
              ></v-date-picker>
            </v-menu>
            <v-textarea
              label="Назначение платежа"
              v-model.trim="obj.purpose"
              auto-grow
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
            </v-textarea>
            <v-radio-group
              v-model="obj.type"
              label="Тип начисления/взносов"
              mandatory>
              <v-radio label="Членские" value="членские"></v-radio>
              <v-radio label="Целевые" value="целевые"></v-radio>
              <v-radio label="Нецелевые" value="нецелевые"></v-radio>
              <v-radio label="Электроэнергия" value="электроэнергия"></v-radio>
            </v-radio-group>
            <template v-if="obj.type === 'нецелевые' || obj.type === 'электроэнергия'">
              <v-radio-group v-if="obj.type === 'нецелевые'"
                v-model="obj.extra.separateAmount"
                mandatory>
                <v-radio label="Одинаковая для всех сумма" :value="false"></v-radio>
                <v-radio label="Разная сумма" :value="true"></v-radio>
              </v-radio-group>
              <v-text-field v-if="!obj.extra.separateAmount && obj.type === 'нецелевые'"
                v-model.number="obj.amount"
                label="Сумма нецелевого взноса"
                :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
              </v-text-field>
              <template v-else>
                <v-data-table
                  :key="JSON.stringify(obj.extra.shares)"
                  :headers="headers"
                  :items="obj.extra.shares"
                  :options.sync="listOptions"
                  :footer-props="listFooterProps">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog v-model="shareChooseDialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on">
                              Выбрать участки
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">Выбрать участки</span>
                            </v-card-title>
                            <v-card-text>
                              <v-text-field
                                v-model="shareSearch"
                                append-icon="mdi-magnify"
                                label="Найти"
                                @input="updateSharesProps()"
                              ></v-text-field>
                              <v-data-table
                                v-model="selectedShares"
                                :headers="dialogHeaders"
                                :items="shares.data"
                                :options.sync="dialogListOptions"
                                :server-items-length="shares.total"
                                :loading="dialogDataLoading"
                                :footer-props="dialogListFooterProps"
                                show-select
                                @update:options="updateSharesProps()">
                                <template v-slot:item.full_name="{ item }">
                                  {{ item.last_name }} {{ item.name }} {{ item.mid_name }}
                                </template>
                              </v-data-table>
                              <v-menu v-model="dialogDateTillMenu" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field clearable
                                    :value="dateTill"
                                    label="Оплатить до"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="obj.date_till = null"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="obj.date_till"
                                  @change="dialogDateTillMenu = false"
                                  :min="new Date().toISOString()"
                                ></v-date-picker>
                              </v-menu>
                              <v-text-field
                                v-model.number="obj.amount"
                                :label="obj.type === 'электроэнергия' ? 'Сумма начисления' : 'Сумма нецелевого взноса'"
                                :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
                              </v-text-field>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text @click="shareChooseClose()">Отменить</v-btn>
                              <v-btn :disabled="!selectedShares.length || !obj.date_till || !obj.amount" text @click="shareChooseSave()">Выбрать</v-btn>
                            </v-card-actions>
                          </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-dialog v-model="shareDeleteDialog" max-width="300px">
                      <v-card>
                        <v-card-text class="text-center py-6">Удалить начисление для этого участка?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="shareDeleteClose()">Нет</v-btn>
                          <v-btn text @click="shareDeleteConfirm()">Да</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.date_till="{ item }">
                    {{ $moment(new Date(item.date_till)).format('DD.MM.YYYY') }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteShare(item)">mdi-trash-can-outline</v-icon>
                  </template>
                </v-data-table>
              </template>
            </template>
            <template v-else>
              <v-text-field
                v-model="obj.amount"
                :label="obj.type === 'членские' ? 'Введите значение K. Размер взносов будет рассчитан: K×S' : 'Сумма целевого взноса'"
                :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
              </v-text-field>
            </template>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      obj: {
        extra: {}
      },
      formValid: false,
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      headers: [
        { text: 'К-Л-Д', value: 'clh' },
        { text: 'ФИО владельца', value: 'full_name' },
        { text: 'Сумма', value: 'amount', sortable: false, filterable: false },
        { text: 'Оплатить до', value: 'date_till', sortable: false, filterable: false },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listOptions: {
        sortBy: ['clh'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      dateTillMenu: false,
      dialogDateTillMenu: false,
      shareChooseDialog: false,
      shareDeleteDialog: false,
      dialogData: {
        dateTill: null,
        amount: null
      },
      dialogHeaders: [
        { text: 'Куст-линия-дача', value: 'clh' },
        { text: 'ФИО владельца', value: 'full_name' },
      ],
      dialogListOptions: {
        itemsPerPage: 5,
        sortBy: ['clh'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      shares: {},
      shareSearch: '',
      selectedShares: [],
      dialogDataLoading: false,
      dialogListFooterProps: {
        showFirstLastPage: false,
        itemsPerPageOptions: [5, 10, 20]
      },
      shareEditedIndex: -1,
      shareItem: {}
    }
  },
  computed: {
    dateTill: function () {
      return this.obj.date_till ? this.$moment(this.obj.date_till).format('DD.MM.YYYY') : ''
    }
  },
  watch: {
    shareChooseDialog: function (val) {
      val || this.shareChooseClose()
    },
    shareDeleteDialog: function (val) {
      val || this.shareDeleteClose()
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('CHARGES_BOTTOMSHEET_CLOSE')
      this.$store.commit('CHARGES_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    editCharge: function () {
      if (this.$refs.chargeForm.validate()) {
        this.formValid = false
        this.$store.dispatch('CHARGES_UPDATE', this.obj).then(() => {
          if (this.$store.getters.chargesStatus === 'success') {
            this.$store.commit('SNACKBAR_SET', 'Сохранено успешно')
            this.backButton()
          }
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
          this.backButton()
        })
      }
    },
    getCharge: function () {
      this.$store.dispatch('CHARGES_GET_CHARGE', this.$store.getters.charge).then(() => {
        if (this.$store.getters.chargesStatus === 'success') {
          this.obj = this.$store.getters.charge
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    shareChooseClose: function () {
      this.shareChooseDialog = false
      this.$nextTick(() => {
        this.dialogData = {
          dateTill: null,
          amount: null
        }
        this.shareSearch = ''
        this.selectedShares = []
      })
    },
    shareChooseSave: function () {
      this.obj.extra.shares = this.obj.extra.shares || []
      this.selectedShares.forEach(function (share) {
        let shareItem = {
          id: share.id,
          clh: share.clh,
          full_name: share.last_name + ' ' + share.name + ' ' + share.mid_name,
          date_till: this.obj.date_till,
          amount: this.obj.amount
        }
        this.obj.extra.shares.push(shareItem)
      }, this)
      this.$forceUpdate()
      this.shareChooseClose()
    },
    shareDeleteConfirm: function () {
      this.obj.extra.shares.splice(this.shareEditedIndex, 1)
      this.$forceUpdate()
      this.shareDeleteClose()
    },
    shareDeleteClose: function () {
      this.shareDeleteDialog = false
      this.$nextTick(() => {
        this.shareItem = {}
        this.shareEditedIndex = -1
      })
    },
    deleteShare: function (item) {
      this.shareEditedIndex = this.obj.extra.shares.indexOf(item)
      this.shareItem = JSON.parse(JSON.stringify(item))
      this.shareDeleteDialog = true
    },
    updateSharesProps: function () {
      if (this.dialogListOptions.sortBy.length === 1) this.dialogListOptions.mustSort = true
      else this.dialogListOptions.mustSort = false
      this.loadSharesData()
    },
    loadSharesData: function () {
      this.dialogDataLoading = true
      let options = {
        page: this.dialogListOptions.page,
        itemsPerPage: this.dialogListOptions.itemsPerPage,
        sortBy: this.dialogListOptions.sortBy,
        sortDesc: this.dialogListOptions.sortDesc,
        search: this.shareSearch
      }
      this.$store.dispatch('SHARES_SEARCH_GET', options).then(() => {
        this.dialogDataLoading = false
        if (this.$store.getters.sharesStatus === 'success') {
          this.shares = this.$store.getters.shares
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dialogDataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.charge
      if (this.obj.id === undefined) this.backButton()
      else if (this.obj.id !== null) {
        this.getCharge()
        this.$root.setTitle('Изменение начисления')
      }
    } catch (e) {
      this.backButton()
    }
  }
}
</script>

<style scoped>

</style>
