<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Список пользователей</v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      Список пользователей
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      listData: []
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    }
  },
  methods: {
    getData: function () {
      this.$store.dispatch('USERS_GET').then(() => {
        if (this.$store.getters.usersStatus === 'success') {
          this.listData = this.$store.getters.users
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    }
  },
  mounted: function () {
    this.getData()
    this.$root.setTitle('Список пользователей')
  }
}
</script>

<style scoped>

</style>
